import React, { useState } from 'react';
import '../../styles/modals/Modal.css';

function LinkNodesModal({ onClose, onSubmit, walletId, availableNodes }) {
  const [selectedNodes, setSelectedNodes] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedNodes);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>[↔] Link Nodes to Wallet</h3>
          <button onClick={onClose} className="close-button">[×]</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Select Nodes:</label>
            <div className="nodes-list">
              {availableNodes.map(node => (
                <div key={node.id} className="node-select-item">
                  <label>
                    <input
                      type="checkbox"
                      value={node.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedNodes([...selectedNodes, node.id]);
                        } else {
                          setSelectedNodes(selectedNodes.filter(id => id !== node.id));
                        }
                      }}
                    />
                    {node.type} (Server: {node.server_name})
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-actions">
            <button type="submit" className="submit-button" disabled={selectedNodes.length === 0}>
              [✓] Link Selected Nodes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LinkNodesModal; 