import React, { useState } from 'react';
import '../../styles/modals/Modal.css';

// Списки слов для генерации имен
const ADJECTIVES = [
  'Alpha', 'Beta', 'Gamma', 'Delta', 'Epsilon', 
  'Quantum', 'Cosmic', 'Stellar', 'Nebula', 'Solar',
  'Lunar', 'Arctic', 'Blazing', 'Crystal', 'Digital',
  'Electric', 'Fusion', 'Global', 'Hyper', 'Infinite',
  'Jade', 'Kinetic', 'Laser', 'Mega', 'Neo',
  'Omega', 'Plasma', 'Quasar', 'Rapid', 'Sonic',
  'Thunder', 'Ultra', 'Vertex', 'Wave', 'Xenon',
  'Yield', 'Zero', 'Apex', 'Burst', 'Core',
  'Dawn', 'Echo', 'Flow', 'Glow', 'Helix',
  'Ion', 'Jump', 'Kick', 'Lux', 'Mist'
];

const NOUNS = [
  'Node', 'Core', 'Hub', 'Grid', 'Net',
  'Base', 'Nexus', 'Point', 'Port', 'Gate',
  'Link', 'Mesh', 'Cloud', 'Stack', 'Forge',
  'Matrix', 'Pulse', 'Sphere', 'Tower', 'Unit',
  'Vector', 'Web', 'Zone', 'Array', 'Bridge',
  'Cache', 'Dock', 'Edge', 'Frame', 'Host',
  'Index', 'Junction', 'Kernel', 'Layer', 'Mirror',
  'Network', 'Orbit', 'Portal', 'Queue', 'Relay',
  'Stream', 'Thread', 'Uplink', 'Vault', 'Wire',
  'Axis', 'Beam', 'Cell', 'Data', 'Engine'
];

const LOCATIONS = [
  'Alpha', 'Beta', 'Prime', 'Core', 'Main',
  'East', 'West', 'North', 'South', 'Central',
  'EU', 'US', 'AS', 'AF', 'SA',
  'NYC', 'LON', 'TKY', 'SG', 'SYD',
  'LAX', 'FRA', 'AMS', 'HKG', 'SEA'
];

function ServerModal({ onClose, onSubmit, userId }) {
  // Функция генерации случайного имени
  const generateServerName = () => {
    const adjective = ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)];
    const noun = NOUNS[Math.floor(Math.random() * NOUNS.length)];
    const location = LOCATIONS[Math.floor(Math.random() * LOCATIONS.length)];
    return `${adjective} ${noun} ${location}`;
  };

  const [formData, setFormData] = useState({
    name: '',
    ip: '',
    password: '',
    userId: userId
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Если имя не заполнено, генерируем случайное
    const submissionData = {
      ...formData,
      name: formData.name.trim() || generateServerName()
    };
    onSubmit(submissionData);
  };

  return (
    <div className="enterprise-modal-overlay">
      <div className="enterprise-modal-content">
        <div className="enterprise-modal-header">
          <h3>[+] Connect Existing Server</h3>
          <button onClick={onClose} className="enterprise-action-btn enterprise-delete-btn">[×]</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="enterprise-form-group">
            <label>Server Name: (optional)</label>
            <div className="input-with-button">
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder="Leave empty for random name"
              />
              <button 
                type="button" 
                className="enterprise-action-btn"
                onClick={() => setFormData({ ...formData, name: generateServerName() })}
              >
                [↺]
              </button>
            </div>
          </div>
          <div className="enterprise-form-group">
            <label>Server IP:</label>
            <input
              type="text"
              value={formData.ip}
              onChange={(e) => setFormData({ ...formData, ip: e.target.value })}
              placeholder="e.g. 192.168.1.100"
              required
            />
          </div>
          <div className="enterprise-form-group">
            <label>Root Password:</label>
            <input
              type="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              placeholder="Enter server's root password"
              required
            />
          </div>
          <div className="enterprise-modal-actions">
            <button type="submit" className="enterprise-action-btn">
              [✓] Connect Server
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ServerModal; 