// src/App.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/HomeRetro.css';
import { Helmet } from 'react-helmet-async';
import AsciiEarth from '../components/AsciiEarth';
import AsciiTorus from '../components/AsciiTorus';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>xNod3 - Private Node Club & Enterprise Solutions</title>
        <meta name="description" content="Join xNod3 private club for exclusive node deployment tools, guides and support. Enterprise-grade solutions available." />
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://xnod3.com/" />
        <meta property="og:title" content="xNod3 - Private Node Club & Enterprise Solutions" />
        <meta property="og:description" content="Join xNod3 private club for exclusive node deployment tools, guides and support. Enterprise-grade solutions available." />
        <meta property="og:image" content="https://xnod3.com/images/p-xnod3.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://xnod3.com/" />
        <meta property="twitter:title" content="xNod3 - Private Node Club & Enterprise Solutions" />
        <meta property="twitter:description" content="Join xNod3 private club for exclusive node deployment tools, guides and support. Enterprise-grade solutions available." />
        <meta property="twitter:image" content="https://xnod3.com/images/p-xnod3.png" />
      </Helmet>
      
      <div className="xn-page-container">
        <HeroSection />
        <WhoWeAre />
        <AccessLevels />
        <TechnicalDetails />
        <Testimonials />
        <FinalCTA />
      </div>
    </>
  );
};


const HeroSection = () => {
  const [titleWord, setTitleWord] = useState('Private');
  const [globePoints, setGlobePoints] = useState([
    { x: 30, y: 30, active: true },  // Frankfurt
    { x: 70, y: 40, active: true },  // Singapore
    { x: 20, y: 35, active: true },  // Virginia
    { x: 25, y: 28, active: true },  // London
    { x: 80, y: 35, active: true }   // Tokyo
  ]);
  
  useEffect(() => {
    const words = ['Private', 'Secure', 'Advanced', 'Enterprise', 'Blockchain'];
    let index = 0;
    
    const wordInterval = setInterval(() => {
      index = (index + 1) % words.length;
      setTitleWord(words[index]);
    }, 3000);

    const globeInterval = setInterval(() => {
      setGlobePoints(prev => prev.map(point => ({
        ...point,
        active: Math.random() > 0.3
      })));
    }, 2000);

    return () => {
      clearInterval(wordInterval);
      clearInterval(globeInterval);
    };
  }, []);

  return (
    <section className="xn-hero-section">
      <div className="xn-circuit-lines" />
      <div className="xn-hero-content">
        <div className="xn-globe-background">
          <AsciiEarth dataPoints={globePoints} />
          <div className="xn-globe-overlay"></div>
        </div>
        <h1 className="xn-hero-title">
          xNod3: Your <span className="xn-hero-word-change">{titleWord}</span> Node Club
          <span className="xn-hero-title-changing">& Enterprise Solutions</span>
        </h1>
        
        <div className="xn-hero-description">
          <div className="xn-terminal-line">
            <span className="xn-line-label">[ACCESS]</span> :: 
            <span className="xn-line-item"><span className="xn-line-icon">⚡</span>tools</span> | 
            <span className="xn-line-item"><span className="xn-line-icon">◈</span>guides</span> | 
            <span className="xn-line-item"><span className="xn-line-icon">⟡</span>24/7_support</span>
            <span className="xn-blink">_</span>
          </div>
          <div className="xn-terminal-line enterprise">
            <span className="xn-line-label">[ENTERPRISE]</span> :: 
            <span className="xn-line-item"><span className="xn-line-icon">⬡</span>large-scale</span> | 
            <span className="xn-line-item"><span className="xn-line-icon">⚛</span>high-performance</span> | 
            <span className="xn-line-item"><span className="xn-line-icon">⬢</span>secure</span>
            <span className="xn-blink">_</span>
          </div>
        </div>
        
        <div className="xn-hero-buttons">
          <Link to="/private" className="xn-hero-button">
            [Join Private Club]
          </Link>
          <Link to="/enterprise" className="xn-hero-button xn-enterprise-button">
            [Request Enterprise Quote]
          </Link>
        </div>
      </div>
    </section>
  );
};

const WhoWeAre = () => {
  return (
    <section className="xn-who-section">
      <div className="xn-terminal-block">
        <div className="xn-window-header">
          <div className="xn-window-controls">
            <div className="xn-window-button xn-window-close"></div>
            <div className="xn-window-button xn-window-minimize"></div>
            <div className="xn-window-button xn-window-maximize"></div>
          </div>
          <div className="xn-window-path">~/xnod3/about.js - xNod3 Terminal</div>
        </div>

        <div className="xn-terminal-content">
          <div className="xn-torus-container">
            <AsciiTorus />
          </div>
          <div className="xn-term-section">
            <div className="xn-term-line">
              <span className="xn-term-highlight">class</span> xNod3 {'{'}
            </div>
            <div className="xn-term-description">
              xNod3 is an <span className="xn-term-accent">exclusive crypto club</span> where members get access to:
              <ul className="xn-term-list">
                <li><span className="xn-term-value">unique_tools</span></li>
                <li><span className="xn-term-value">deployment_guides</span></li>
                <li><span className="xn-term-value">private_support_24_7</span></li>
              </ul>
              For enterprise clients, we provide <span className="xn-term-accent">full-scale turnkey solutions</span>.
            </div>
          </div>

          <div className="xn-term-section benefits">
            <div className="xn-term-line">
              <span className="xn-term-highlight">function</span> benefits() {'{'}
            </div>
            <div className="xn-term-description">
              <span className="xn-term-arrow">→</span> Earn from nodes
              <br />
              <span className="xn-term-arrow">→</span> Optimize profits
              <br />
              <span className="xn-term-arrow">→</span> Access private projects
            </div>
            <div className="xn-term-line">{'}'}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AccessLevels = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.xn-access-content');
    
    const handleMouseMove = (e, card) => {
      const rect = card.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      card.style.setProperty('--x', `${x}%`);
      card.style.setProperty('--y', `${y}%`);
    };

    cards.forEach(card => {
      card.addEventListener('mousemove', (e) => handleMouseMove(e, card));
    });

    return () => {
      cards.forEach(card => {
        card.removeEventListener('mousemove', (e) => handleMouseMove(e, card));
      });
    };
  }, []);

  return (
    <section className="xn-access-section">
      {/* Private Club Card */}
      <div className="xn-access-card xn-private-club">
        <div className="xn-window-header" data-path="private_access.sh">
          <div className="xn-window-controls">
            <div className="xn-window-button xn-window-close"></div>
            <div className="xn-window-button xn-window-minimize"></div>
            <div className="xn-window-button xn-window-maximize"></div>
          </div>
          <div className="xn-window-path">private_access.sh - xNod3 Terminal</div>
        </div>

        <div className="xn-access-content">
          <div className="xn-access-status">
            <div className="xn-status-item">
              <span className="xn-status-label">[STATUS]</span>
              <span className="xn-status-value">ACCEPTING_MEMBERS</span>
            </div>
            <div className="xn-status-item">
              <span className="xn-status-label">[SLOTS]</span>
              <span className="xn-status-value blink">42_AVAILABLE</span>
            </div>
            <div className="xn-status-item">
              <span className="xn-status-label">[ACCESS_KEY]</span>
              <span className="xn-status-value">xNOD3_SUBSCRIPTION</span>
            </div>
          </div>

          <div className="xn-access-features">
            <div className="xn-feature-header">
              <span className="xn-term-highlight">function</span> getPrivateAccess() {'{'}
            </div>
            <ul className="xn-feature-list">
              <li>
                <span className="xn-feature-bullet">⚡</span>
                <span className="xn-feature-command">tools</span>
                <span className="xn-feature-desc">Automated deployment scripts</span>
              </li>
              <li>
                <span className="xn-feature-bullet">◈</span>
                <span className="xn-feature-command">guides</span>
                <span className="xn-feature-desc">Step-by-step node setup guides</span>
              </li>
              <li>
                <span className="xn-feature-bullet">⟡</span>
                <span className="xn-feature-command">24/7_support</span>
                <span className="xn-feature-desc">Private technical assistance</span>
              </li>
            </ul>
            <div className="xn-feature-footer">{'}'}</div>
          </div>

          <div className="xn-button-container">
            <Link to="/private" className="xn-terminal-button">
              <span className="xn-button-prompt">$</span>
              <span className="xn-button-command">join private_club --access-level=premium</span>
            </Link>
          </div>
        </div>
      </div>

      {/* Enterprise Card */}
      <div className="xn-access-card xn-enterprise">
        <div className="xn-window-header">
          <div className="xn-window-controls">
            <div className="xn-window-button xn-window-close"></div>
            <div className="xn-window-button xn-window-minimize"></div>
            <div className="xn-window-button xn-window-maximize"></div>
          </div>
          <div className="xn-window-path">enterprise_solution.sh - xNod3 Terminal</div>
        </div>

        <div className="xn-access-content">
          <div className="xn-access-status">
            <div className="xn-status-item">
              <span className="xn-status-label">[SERVICE]</span>
              <span className="xn-status-value">WHITE_GLOVE</span>
            </div>
            <div className="xn-status-item">
              <span className="xn-status-label">[UPTIME]</span>
              <span className="xn-status-value">99.9%_GUARANTEED</span>
            </div>
            <div className="xn-status-item">
              <span className="xn-status-label">[SCALE]</span>
              <span className="xn-status-value">UNLIMITED</span>
            </div>
          </div>

          <div className="xn-access-features">
            <div className="xn-feature-header">
              <span className="xn-term-highlight">function</span> getEnterpriseSolution() {'{'}
            </div>
            <ul className="xn-feature-list">
              <li>
                <span className="xn-feature-bullet">⬡</span>
                <span className="xn-feature-command">large-scale</span>
                <span className="xn-feature-desc">Deploy dozens/hundreds of nodes</span>
              </li>
              <li>
                <span className="xn-feature-bullet">⚛</span>
                <span className="xn-feature-command">high-performance</span>
                <span className="xn-feature-desc">Professional setup and monitoring</span>
              </li>
              <li>
                <span className="xn-feature-bullet">⬢</span>
                <span className="xn-feature-command">secure</span>
                <span className="xn-feature-desc">SLA and personal manager</span>
              </li>
            </ul>
            <div className="xn-feature-footer">{'}'}</div>
          </div>

          <Link to="/enterprise" className="xn-terminal-button enterprise">
            <span className="xn-button-prompt">$</span>
            <span className="xn-button-command">request enterprise_quote --type=custom</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

const TechnicalDetails = () => {
  return (
    <section className="xn-tech-section">
      <div className="xn-terminal-block">
        <div className="xn-window-header">
          <div className="xn-window-controls">
            <div className="xn-window-button xn-window-close"></div>
            <div className="xn-window-button xn-window-minimize"></div>
            <div className="xn-window-button xn-window-maximize"></div>
          </div>
          <div className="xn-window-path">networks_status.sh - xNod3 Terminal</div>
        </div>

        <div className="xn-terminal-content">
          <div className="xn-network-header">
            <span className="xn-term-highlight">[SUPPORTED_NETWORKS]</span> :: status_check --display=recent
            <div className="xn-network-timestamp">Last update: <span className="xn-timestamp-value">calculating...</span></div>
          </div>

          <div className="xn-network-status">
            <div className="xn-network-item">
              <div className="xn-network-info">
                <div className="xn-network-name">SOLANA</div>
                <div className="xn-network-meta">
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Nodes:</span>
                    <span className="xn-meta-value">1</span>
                  </span>
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Uptime:</span>
                    <span className="xn-meta-value">99.99%</span>
                  </span>
                </div>
              </div>
              <div className="xn-network-scripts">
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  deploy.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  monitor.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  optimize.sh
                </div>
              </div>
            </div>

            <div className="xn-network-item">
              <div className="xn-network-info">
                <div className="xn-network-name">NEAR Protocol</div>
                <div className="xn-network-meta">
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Nodes:</span>
                    <span className="xn-meta-value">1</span>
                  </span>
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Uptime:</span>
                    <span className="xn-meta-value">99.98%</span>
                  </span>
                </div>
              </div>
              <div className="xn-network-scripts">
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  deploy.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  monitor.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  optimize.sh
                </div>
              </div>
            </div>

            <div className="xn-network-item">
              <div className="xn-network-info">
                <div className="xn-network-name">OpenLedger</div>
                <div className="xn-network-meta">
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Nodes:</span>
                    <span className="xn-meta-value counting">6,482</span>
                  </span>
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Uptime:</span>
                    <span className="xn-meta-value">99.99%</span>
                  </span>
                </div>
              </div>
              <div className="xn-network-scripts">
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  deploy.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  monitor.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  optimize.sh
                </div>
              </div>
            </div>

            <div className="xn-network-item">
              <div className="xn-network-info">
                <div className="xn-network-name">Ritual</div>
                <div className="xn-network-meta">
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Nodes:</span>
                    <span className="xn-meta-value counting">2,891</span>
                  </span>
                  <span className="xn-meta-item">
                    <span className="xn-meta-label">Uptime:</span>
                    <span className="xn-meta-value">99.97%</span>
                  </span>
                </div>
              </div>
              <div className="xn-network-scripts">
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  deploy.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  monitor.sh
                </div>
                <div className="xn-script-item">
                  <span className="xn-script-status active">▶</span>
                  optimize.sh
                </div>
              </div>
            </div>

            <div className="xn-network-more">
              <div className="xn-more-info">
                <span className="xn-more-icon">...</span>
                <div className="xn-more-text">
                  <div>And 15+ more networks</div>
                  <div className="xn-more-details">Use --display=all to see full list</div>
                </div>
              </div>
              <div className="xn-more-stats">
                <span className="xn-meta-item">
                  <span className="xn-meta-label">Total Networks:</span>
                  <span className="xn-meta-value">19</span>
                </span>
                <span className="xn-meta-item">
                  <span className="xn-meta-label">Scripts:</span>
                  <span className="xn-meta-value counting">45+</span>
                </span>
              </div>
            </div>
          </div>

          <div className="xn-network-footer">
            <div className="xn-total-stats">
              <div className="xn-total-item">
                <span className="xn-total-label">Total Nodes:</span>
                <span className="xn-total-value counting">12,482</span>
              </div>
              <div className="xn-total-item">
                <span className="xn-total-label">Average Uptime:</span>
                <span className="xn-total-value">99.98%</span>
              </div>
              <div className="xn-total-item">
                <span className="xn-total-label">Active Scripts:</span>
                <span className="xn-total-value counting">138</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Testimonials = () => {
  return (
    <section className="xn-testimonials-section">
      <div className="xn-terminal-block">
        <div className="xn-window-header">
          <div className="xn-window-controls">
            <div className="xn-window-button xn-window-close"></div>
            <div className="xn-window-button xn-window-minimize"></div>
            <div className="xn-window-button xn-window-maximize"></div>
          </div>
          <div className="xn-window-path">feedback_logs.md - xNod3 Terminal</div>
        </div>

        <div className="xn-terminal-content">
          <div className="xn-feedback-header">
            <span className="xn-term-highlight">[CLIENT_FEEDBACK]</span> :: latest_reviews --sort=rating
            <div className="xn-feedback-stats">
              <span className="xn-stat-item">Total Reviews: 142</span>
              <span className="xn-stat-item">Avg Rating: 4.9/5</span>
            </div>
          </div>

          <div className="xn-feedback-list">
            <div className="xn-feedback-item">
              <div className="xn-feedback-meta">
                <div className="xn-user-info">
                  <div className="xn-user-avatar">
                    <pre className="xn-avatar-art">
{`  ╭━━━╮ 
  ┃>_<┃
  ┃|||┃
  ╰━━━╯`}
                    </pre>
                  </div>
                  <div className="xn-user-details">
                    <div className="xn-user-name">@node_master</div>
                    <div className="xn-user-stats">
                      <span className="xn-stat">Nodes: 24</span>
                      <span className="xn-stat">Since: 2023</span>
                    </div>
                  </div>
                </div>
                <div className="xn-review-info">
                  <div className="xn-review-rating">★★★★★</div>
                  <div className="xn-review-date">$ date: 2024-02-15</div>
                </div>
              </div>
              <div className="xn-feedback-content">
                <div className="xn-comment-header">$ cat review.txt</div>
                <div className="xn-comment-text">
                  "Deployed 15+ nodes with zero issues. The automation scripts are pure gold. Support team response time &lt; 10min!"
                </div>
                <div className="xn-verification">
                  <span className="xn-verify-icon">✓</span>
                  <span className="xn-verify-text">Verified Enterprise Client</span>
                </div>
              </div>
            </div>

            <div className="xn-feedback-item">
              <div className="xn-feedback-meta">
                <div className="xn-user-info">
                  <div className="xn-user-avatar">
                    <pre className="xn-avatar-art">
{`  ╭━━━╮
  ┃•̀_•́┃
  ┃<|>┃
  ╰━━━╯`}
                    </pre>
                  </div>
                  <div className="xn-user-details">
                    <div className="xn-user-name">@crypto_whale</div>
                    <div className="xn-user-stats">
                      <span className="xn-stat">Nodes: 156</span>
                      <span className="xn-stat">Since: 2022</span>
                    </div>
                  </div>
                </div>
                <div className="xn-review-info">
                  <div className="xn-review-rating">★★★★★</div>
                  <div className="xn-review-date">$ date: 2024-01-28</div>
                </div>
              </div>
              <div className="xn-feedback-content">
                <div className="xn-comment-header">$ cat review.txt</div>
                <div className="xn-comment-text">
                  "Running a massive node infrastructure with xNod3. Their enterprise solution handles everything. 99.99% uptime is real!"
                </div>
                <div className="xn-verification">
                  <span className="xn-verify-icon">✓</span>
                  <span className="xn-verify-text">Verified Enterprise Client</span>
                </div>
              </div>
            </div>

            <div className="xn-feedback-more">
              <div className="xn-more-info">
                <span className="xn-more-icon">...</span>
                <div className="xn-more-text">
                  <div>View more reviews</div>
                  <div className="xn-more-details">$ reviews --page=2 --limit=10</div>
                </div>
              </div>
              <div className="xn-more-stats">
                <span className="xn-stat">Total Pages: 15</span>
                <span className="xn-stat">Reviews/Page: 10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FinalCTA = () => {
  return (
    <section className="xn-final-cta">
      <h2 className="xn-cta-title">Ready to join the xNod3 revolution?</h2>
      <div className="xn-hero-buttons">
        <Link to="/private" className="xn-hero-button">
          [Purchase Subscription]
        </Link>
        <Link to="/enterprise" className="xn-hero-button xn-enterprise-button">
          [Get Enterprise Offer]
        </Link>
      </div>
    </section>
  );
};


export default HomePage;
