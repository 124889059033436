import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/common/TerminalWindow.css';
import '../styles/Enterprise.css';
import '../styles/EnterpriseRetro.css';
import { Link } from 'react-router-dom';

const services = [
  {
    id: 'deployment',
    title: 'Node Deployment',
    icon: '[>]',
    description: 'Secure, scalable, and fast deployment of blockchain nodes across testnets and mainnets, fully managed by our expert team.',
    stats: 'Deployment Time: ~2h',
    pricing: {
      testnet: 'Service provided for a percentage of future potential rewards + server costs (monthly or as needed).',
      mainnet: 'Monthly payment plan or customized billing options available.'
    }
  },
  {
    id: 'monitoring',
    title: '24/7 Monitoring & Support',
    icon: '[*]',
    description: 'Continuous performance monitoring and real-time troubleshooting to ensure zero downtime and optimal operations.',
    stats: 'Uptime: 99.99%',
    pricing: {
      testnet: 'Percentage of potential rewards + server costs.',
      mainnet: 'Monthly or customized payment plan for uninterrupted support.'
    }
  },
  {
    id: 'optimization',
    title: 'Profit Optimization & ROI Tracking',
    icon: '[%]',
    description: 'Maximize returns with our data-driven approach to performance and revenue tracking.',
    stats: 'Avg ROI: +35%',
    pricing: {
      testnet: 'Based on future rewards and server costs.',
      mainnet: 'Monthly payment based on infrastructure scale.'
    }
  }
];

const projects = [
  {
    name: 'Chasm Network',
    network: 'Testnet',
    description: 'Large-scale node deployment with automated management',
    metrics: {
      nodesPerServer: '10-100',
      serverCost: '$10/month',
      mintingCost: '$0.01/node',
      rewards: '$150-$200/node'
    },
    testimonial: 'The team at xNod3 provided seamless node deployment for Chasm Network, resulting in exceptional returns on our investment.'
  },
  {
    name: 'Solana TdS',
    network: 'Testnet',
    description: 'Tour de SOL testnet participation with high-performance validation',
    metrics: {
      monthlyRewards: '$500 in SOL',
      monthlyCost: '$24',
      totalEarnings2021: '~$15,000',
      totalEarningsAll: '~$2,300,000'
    },
    testimonial: 'Participated in Tour de SOL with exceptional uptime and performance metrics, contributing to network stability and earning significant rewards.'
  }
];

const steps = [
  {
    id: 'assessment',
    title: 'Assessment',
    icon: '>',
    description: 'Detailed analysis of your infrastructure needs.'
  },
  {
    id: 'deployment',
    title: 'Deployment',
    icon: '//',
    description: 'Customized node deployment, optimized for performance.'
  },
  {
    id: 'monitoring',
    title: 'Monitoring',
    icon: '*',
    description: 'Real-time monitoring and maintenance for seamless operations.'
  },
  {
    id: 'optimization',
    title: 'Optimization',
    icon: '%',
    description: 'Continuous performance enhancements for higher profitability.'
  }
];

const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div 
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && <div className="tooltip-content">{content}</div>}
    </div>
  );
};

const serverTiers = [
  { id: 'basic', name: 'Basic', cost: 5, icon: '[◈]' },
  { id: 'standard', name: 'Standard', cost: 11, icon: '[◇]' },
  { id: 'advanced', name: 'Advanced', cost: 50, icon: '[⬡]' },
  { id: 'premium', name: 'Premium', cost: 100, icon: '[⟡]' }
];

const nodeCounts = [
  { id: 'micro', name: '1-3 nodes', multiplier: 1, icon: '[◈]' },
  { id: 'small', name: '4-10 nodes', multiplier: 1.5, icon: '[◈◈]' },
  { id: 'medium', name: '11-25 nodes', multiplier: 2, icon: '[◈◈◈]' },
  { id: 'large', name: '26-50 nodes', multiplier: 2.5, icon: '[◈◈◈◈]' }
];

function EnterprisePage() {
  const [activeService, setActiveService] = useState(null);
  const [typedText, setTypedText] = useState('');
  const fullText = "Enterprise Solutions for Blockchain Infrastructure";
  
  const [serverCost, setServerCost] = useState(100);
  const [expectedRewards, setExpectedRewards] = useState(500);
  const [serverTier, setServerTier] = useState('basic');
  const [nodeCount, setNodeCount] = useState('small');
  const [isUpdating, setIsUpdating] = useState(false);

  const calculateShare = () => {
    return Math.round(expectedRewards * 0.2);
  };

  const calculateTotal = () => {
    return calculateServerCost() + calculateManagementFee();
  };

  const calculateServerCost = () => {
    const tier = serverTiers.find(t => t.id === serverTier);
    return tier ? tier.cost : 0;
  };

  const calculateManagementFee = () => {
    const count = nodeCounts.find(n => n.id === nodeCount);
    return calculateServerCost() * (count ? count.multiplier : 1);
  };

  // Функция для получения иконок метрик
  const getIcon = (key) => {
    const keyLower = key.toLowerCase();
    if (keyLower.includes('roi')) return '[📊]';
    if (keyLower.includes('cost')) return '[💵]';
    if (keyLower.includes('earnings') || keyLower.includes('rewards')) return '[📈]';
    if (keyLower.includes('nodes')) return '[◎]';
    if (keyLower.includes('server')) return '[⚡]';
    if (keyLower.includes('uptime')) return '[⟳]';
    return '[▸]';
  };

  const getStepTooltip = (stepTitle) => {
    const tooltips = {
      'Assessment': '> Analyzing infrastructure requirements and performance goals',
      'Deployment': '> Setting up nodes with optimized configurations',
      'Monitoring': '> 24/7 system monitoring and performance tracking',
      'Optimization': '> Continuous improvements based on performance metrics'
    };
    return tooltips[stepTitle] || '';
  };

  const animateValueChange = () => {
    setIsUpdating(true);
    setTimeout(() => setIsUpdating(false), 300);
  };

  useEffect(() => {
    let currentText = '';
    let currentIndex = 0;

    const typeText = () => {
      if (currentIndex < fullText.length) {
        currentText += fullText[currentIndex];
        setTypedText(currentText);
        currentIndex++;
        setTimeout(typeText, 50);
      }
    };

    typeText();
  }, []);

  useEffect(() => {
    animateValueChange();
  }, [serverTier, nodeCount]);

  return (
    <>
      <Helmet>
        <title>Enterprise Solutions - Professional Blockchain Node Deployment | xNod3</title>
        <meta name="description" content="Explore xNod3's Enterprise Solutions for blockchain node deployment, custom infrastructure, and 24/7 technical support. Tailored services to meet large-scale blockchain needs." />
        <meta name="keywords" content="enterprise solutions, blockchain node deployment, custom infrastructure, technical support, xNod3, blockchain services" />
        <link rel="canonical" href="https://xnod3.com/enterprise" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Enterprise Solutions - Professional Blockchain Node Deployment | xNod3" />
        <meta property="og:description" content="Professional blockchain node deployment and custom infrastructure solutions from xNod3." />
        <meta property="og:image" content="/images/enterprise-solutions.png" />
        <meta property="og:url" content="https://xnod3.com/enterprise" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Enterprise Solutions - Professional Blockchain Node Deployment | xNod3" />
        <meta name="twitter:description" content="Discover xNod3's tailored blockchain infrastructure services for enterprise needs." />
        <meta name="twitter:image" content="/images/enterprise-solutions.png" />
      </Helmet>

      <div className="xnenter-container">
        <div className="xnenter-header">
          <div className="xnenter-header-corners"></div>
          <div className="xnenter-header-icons">
            <span className="xnenter-header-icon">⌘</span>
            <span className="xnenter-header-icon">⎔</span>
            <span className="xnenter-header-icon">⎈</span>
            <span className="xnenter-header-icon">⌬</span>
            <span className="xnenter-header-icon">◈</span>
            <span className="xnenter-header-icon">⟡</span>
          </div>
          
          <h1 className="xnenter-typing-text">
            {typedText}<span className="xnenter-cursor">_</span>
          </h1>
          
          <p className="xnenter-header-description">
            Unlock the full potential of blockchain with our enterprise-grade node deployment 
            and management services. Designed for stability, optimized for profitability.
          </p>

          <div className="xnenter-header-badge">
            Enterprise Solutions [v2.0]
          </div>
        </div>

        <section className="xnenter-services-section">
          <div className="xnenter-services-ascii">
            {`
             ╔══════════════════╗
             ║  NODE SERVICES   ║
             ╚══════════════════╝
                ┌─────┐
                │  >  │
                └─────┘
            `}
          </div>
          
          <div className="xnenter-services-grid">
            {services.map(service => (
              <div
                key={service.id}
                className={`xnenter-service-card ${activeService === service.id ? 'active' : ''}`}
                onMouseEnter={() => setActiveService(service.id)}
                onMouseLeave={() => setActiveService(null)}
              >
                <span className="xnenter-service-decorative top-left">[</span>
                <span className="xnenter-service-decorative top-right">]</span>
                <span className="xnenter-service-decorative bottom-left">[</span>
                <span className="xnenter-service-decorative bottom-right">]</span>
                
                <div className="xnenter-service-header">
                  <div className="xnenter-service-icon-wrapper">
                    <div className="xnenter-service-icon-bg"></div>
                    <span className="xnenter-service-icon">{service.icon}</span>
                  </div>
                  <h3 className="xnenter-service-title">{service.title}</h3>
                </div>

                <p className="xnenter-service-description">{service.description}</p>
                
                <div className="xnenter-service-stats">
                  {service.stats}
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="xnenter-projects-section">
          <div className="xnenter-projects-ascii">
            {`
             ┌───────────────────────┐
             │  FEATURED  PROJECTS   │
             └───────────────────────┘
          ╭──────────╮
          │ SUCCESS  │
          ╰──────────╯
            `}
          </div>

          <h2 className="section-title">Featured Projects</h2>
          <div className="xnenter-projects-grid">
            {projects.map(project => (
              <div key={project.name} className="xnenter-project-card">
                <div className="xnenter-project-frame"></div>
                
                <div className="xnenter-project-header">
                  <h3 className="xnenter-project-title">
                    <span className="xnenter-project-icon">[◎]</span>
                    {project.name}
                  </h3>
                  <div className="xnenter-project-network">{project.network}</div>
                </div>

                <p className="xnenter-project-description">{project.description}</p>
                
                <div className="xnenter-project-metrics">
                  <h4 className="xnenter-metrics-title">Performance Metrics</h4>
                  <div className="xnenter-metrics-grid">
                    {project.metrics && Object.entries(project.metrics).map(([key, value]) => (
                      <div key={key} className="xnenter-metric-item">
                        <span className="xnenter-metric-icon">{getIcon(key)}</span>
                        <span className="xnenter-metric-label">
                          {key.split(/(?=[A-Z])/).join(' ')}:
                        </span>
                        <span className="xnenter-metric-value">{value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="xnenter-project-testimonial">
                  <div className="xnenter-testimonial-content">
                    <span className="xnenter-quote-icon">"</span>
                    {project.testimonial}
                    <span className="xnenter-quote-icon">"</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="xnenter-roi-stats-section">
          <div className="xnenter-roi-stats-container">
            <h2 className="section-title">Performance Metrics</h2>
            <div className="xnenter-roi-stats-grid">
              <div className="xnenter-roi-stat-card">
                <div className="xnenter-stat-header"></div>
                <div className="xnenter-stat-content">
                  <div className="xnenter-stat-icon">[%]</div>
                  <div className="xnenter-stat-value">+2000%</div>
                  <div className="xnenter-stat-label">
                    <span className="xnenter-highlight">Chasm</span> Network ROI
                  </div>
                </div>
              </div>

              <div className="xnenter-roi-stat-card">
                <div className="xnenter-stat-header"></div>
                <div className="xnenter-stat-content">
                  <div className="xnenter-stat-icon">[⟁]</div>
                  <div className="xnenter-stat-value">$2.3M+</div>
                  <div className="xnenter-stat-label">
                    Total <span className="xnenter-highlight">Solana</span> Earnings
                  </div>
                </div>
              </div>

              <div className="xnenter-roi-stat-card">
                <div className="xnenter-stat-header"></div>
                <div className="xnenter-stat-content">
                  <div className="xnenter-stat-icon">[∑]</div>
                  <div className="xnenter-stat-value">99.99%</div>
                  <div className="xnenter-stat-label">
                    Average <span className="xnenter-highlight">Uptime</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="xnenter-steps-section">
          <div className="xnenter-steps-ascii">
            {`
             ╔═══════════════════════╗
             ║  DEPLOYMENT PROCESS   ║
             ╚═══════════════════════╝
          ──────▶ ──────▶
            `}
          </div>

          <h2 className="section-title">Deployment Process<span className="xnenter-cursor">_</span></h2>
          <p className="section-description">
            Our step-by-step approach to ensure a seamless and optimized node deployment.
          </p>
          
          <div className="xnenter-steps-progress"></div>
          <div className="xnenter-steps-container">
            {steps.map((step, index) => (
              <div key={step.id} className="xnenter-step-card">
                <div className="xnenter-step-corners"></div>
                <div className="xnenter-step-card-bg"></div>
                <div className="xnenter-step-number">0{index + 1}</div>
                <div className="xnenter-step-status"></div>
                
                <span className="xnenter-step-decorative top-right">⌬</span>
                <span className="xnenter-step-decorative bottom-left">◈</span>
                
                <div className="xnenter-step-header">
                  <div className="xnenter-step-icon-container">
                    <span className="xnenter-step-icon">{step.icon}</span>
                  </div>
                </div>
                
                <div className="xnenter-step-content">
                  <h3 className="xnenter-step-title">{step.title}</h3>
                  <p className="xnenter-step-description">
                    {step.description.split(' ').map((word, i, arr) => {
                      const highlightWords = ['analysis', 'deployment', 'monitoring', 'performance', 'optimized', 'seamless'];
                      const isHighlighted = highlightWords.includes(word.toLowerCase());
                      
                      return (
                        <React.Fragment key={i}>
                          {isHighlighted ? (
                            <span className="xnenter-step-highlight">{word}</span>
                          ) : (
                            word
                          )}
                          {i < arr.length - 1 && ' '}
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
                
                <div className="xnenter-step-tooltip">
                  {getStepTooltip(step.title)}
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="xnenter-pricing-section">
          <div className="xnenter-pricing-ascii">
            {`
             ╔═══════════════════════╗
             ║  NODE COST ANALYZER   ║
             ╚═══════════════════════╝
            `}
          </div>

          <h2 className="section-title">Pricing & Payment Terms<span className="xnenter-cursor">_</span></h2>
          
          <div className="xnenter-pricing-grid">
            <div className="xnenter-pricing-card testnet">
              <div className="xnenter-pricing-corners"></div>
              <div className="xnenter-pricing-bg"></div>
              <div className="xnenter-pricing-status">[ system: online ]</div>
              
              <div className="xnenter-pricing-header">
                <div className="xnenter-pricing-icon-container">
                  <span className="xnenter-pricing-icon">[⟁]</span>
                </div>
                <h3 className="xnenter-pricing-title">
                  Cost <span className="xnenter-highlight">Analyzer</span>
                </h3>
              </div>

              <div className="xnenter-calculator">
                <div className="xnenter-calc-section">
                  <div className="xnenter-calc-label">Server Tier</div>
                  <div className="xnenter-calc-options">
                    {serverTiers.map(tier => (
                      <button 
                        key={tier.id}
                        className={`xnenter-calc-option ${serverTier === tier.id ? 'active' : ''}`}
                        onClick={() => setServerTier(tier.id)}
                        data-tier={tier.id}
                      >
                        <span className="xnenter-option-icon">{tier.icon}</span>
                        {tier.name}
                        <span className="xnenter-option-value">${tier.cost}/mo</span>
                      </button>
                    ))}
                  </div>
                </div>

                <div className="xnenter-calc-section">
                  <div className="xnenter-calc-label">Node Count</div>
                  <div className="xnenter-calc-options">
                    {nodeCounts.map(count => (
                      <button 
                        key={count.id}
                        className={`xnenter-calc-option ${nodeCount === count.id ? 'active' : ''}`}
                        onClick={() => setNodeCount(count.id)}
                      >
                        <span className="xnenter-option-icon">{count.icon}</span>
                        {count.name}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="xnenter-calc-result">
                  <div className="xnenter-result-header">
                    <span className="xnenter-result-icon">[∑]</span>
                    Estimated Costs
                  </div>
                  <div className="xnenter-result-grid">
                    <div className="xnenter-result-item">
                      <span className="xnenter-result-label">Server Cost</span>
                      <span className="xnenter-result-value">${calculateServerCost()}</span>
                    </div>
                    <div className="xnenter-result-item">
                      <span className="xnenter-result-label">Management Fee</span>
                      <span className="xnenter-result-value">${calculateManagementFee()}</span>
                    </div>
                    <div className="xnenter-result-item total">
                      <span className="xnenter-result-label">Total Monthly</span>
                      <span className={`xnenter-result-value ${isUpdating ? 'updating' : ''}`}>${calculateTotal()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="xnenter-pricing-card mainnet">
              <div className="xnenter-pricing-corners"></div>
              <div className="xnenter-pricing-bg"></div>
              
              <div className="xnenter-pricing-header">
                <div className="xnenter-pricing-icon-container">
                  <span className="xnenter-pricing-icon">[⚡]</span>
                </div>
                <h3 className="xnenter-pricing-title">
                  <span className="xnenter-highlight">Mainnet</span> Model
                </h3>
              </div>

              <div className="xnenter-pricing-features">
                <div className="xnenter-feature-item">
                  <span className="xnenter-feature-icon">[⟁]</span>
                  <span className="xnenter-feature-text">
                    <span className="xnenter-highlight">Fixed</span> monthly fee
                  </span>
                </div>
                <div className="xnenter-feature-item">
                  <span className="xnenter-feature-icon">[*]</span>
                  <span className="xnenter-feature-text">
                    <span className="xnenter-highlight">Priority</span> support included
                  </span>
                </div>
              </div>

              <div className="xnenter-plans-container">
                <div className="xnenter-plan-option">
                  <div className="xnenter-plan-header">
                    <span className="xnenter-plan-icon">[>]</span>
                    Basic Plan
                  </div>
                  <div className="xnenter-plan-price">$500<span>/month</span></div>
                  <ul className="xnenter-plan-features">
                    <li>Node deployment & maintenance</li>
                    <li>24/7 monitoring</li>
                    <li>Basic support</li>
                  </ul>
                </div>
                
                <div className="xnenter-plan-option premium">
                  <div className="xnenter-plan-header">
                    <span className="xnenter-plan-icon">[⟡]</span>
                    Premium Plan
                  </div>
                  <div className="xnenter-plan-price">$1000<span>/month</span></div>
                  <ul className="xnenter-plan-features">
                    <li>Advanced optimization</li>
                    <li>Priority support</li>
                    <li>Custom infrastructure</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="xnenter-custom-pricing">
            <div className="xnenter-custom-content">
              <span className="xnenter-custom-icon">[⚡]</span>
              <h3>Need a Custom Solution?</h3>
              <p>Let's discuss your specific requirements and create a tailored plan.</p>
              <button className="xnenter-contact-btn">
                Contact Us <span className="xnenter-btn-icon">[>]</span>
              </button>
            </div>
          </div>
        </section>

      </div>
    </>
  );
}

export default EnterprisePage; 