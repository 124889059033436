import React, { useEffect, useState } from 'react';
import '../styles/AsciiLoader.css';

const AsciiLoader = ({ onLoadingComplete }) => {
  const [frame, setFrame] = useState(0);
  
  const keyFrames = [
    `┌─┐ ┌─┐
└┐└─┘┌┘
└─X─┘
┌┘   └┐
└─┐ ┌─┘`,
    `┌─┐ ┌─┐
└┐└─┘┌┘
└─×─┘
┌┘   └┐
└─┐ ┌─┘`,
    `┌─┐ ┌─┐
└┐└─┘┌┘
└─+─┘
┌┘   └┐
└─┐ ┌─┘`,
    `┌─┐_┌─┐
└┐└─┘┌┘
└─×─┘
┌┘   └┐
└─┐ ┌─┘`,
    `┌─┐_┌─┐
└┐└─┘┌┘
└─X─┘
┌┘ | └┐
└─┐ ┌─┘`,
    `┌─┐_┌─┐
└┐└─┘┌┘
└─×─┘
┌┘ | └┐
└─┐|┌─┘`,
    `┌─┐ ┌─┐
└┐└┼┘┌┘
└─X─┘
┌┘ | └┐
└─┐|┌─┘`,
    `┌─┐ ┌─┐
└┐└┼┘┌┘
└┼X┼┘
┌┘ | └┐
└─┐|┌─┘`,
    `┌─┐ ┌─┐
└┐└┼┘┌┘
└┼+┼┘
┌┘ | └┐
└─┐|┌─┘`,
    `┌─┐ ┌─┐
└┐└┼┘┌┘
└┼×┼┘
┌┘   └┐
└─┐ ┌─┘`
  ];

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setFrame(prev => (prev + 1) % keyFrames.length);
    }, 150);

    const timer = setTimeout(() => {
      onLoadingComplete();
    }, 2000);

    return () => {
      clearInterval(rotationInterval);
      clearTimeout(timer);
    };
  }, [onLoadingComplete]);

  return (
    <div className="ascii-loader">
      <div className="loader-content">
        <div className="ascii-container">
          <pre className="ascii-art">{keyFrames[frame]}</pre>
        </div>
        <div className="loader-text">
          <span className="bracket">[</span>
          <span className="system-text">ACCESS_KEY</span>
          <span className="loading-dots">
            <span className="dot">·</span>
            <span className="dot">·</span>
            <span className="dot">·</span>
          </span>
          <span className="bracket">]</span>
        </div>
      </div>
    </div>
  );
};

export default AsciiLoader; 