import React, { useState } from 'react';
import '../../styles/modals/Modal.css';

function NodeModal({ onClose, onSubmit, nodeTypes, title = "Add Node" }) {
  const [selectedType, setSelectedType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedType);
  };

  return (
    <div className="enterprise-modal-overlay">
      <div className="enterprise-modal-content">
        <div className="enterprise-modal-header">
          <h3>{title}</h3>
          <button onClick={onClose} className="enterprise-action-btn enterprise-delete-btn">
            [×]
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="enterprise-form-group">
            <label>Node Type:</label>
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              required
            >
              <option value="">Select node type</option>
              {nodeTypes.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="enterprise-modal-actions">
            <button type="submit" className="enterprise-action-btn">
              [✓] Add Node{title.includes('All') ? 's' : ''}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NodeModal; 