// src/pages/Projects.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/Projects.css';

const projects = [
  {
    id: "near",
    name: "NEAR Protocol",
    icon: "◈",
    type: "Validator",
    status: "Active",
    nodes: 1,
    uptime: "99.98%",
    nextUpdate: "2024-03-20",
    lastIncident: "None",
    specs: {
      cpu: "32 vCPU",
      ram: "64 GB",
      storage: "2 TB NVMe",
      network: "1 Gbps"
    }
  },
  {
    id: "solana",
    name: "Solana",
    icon: "◎",
    type: "Validator",
    status: "Active",
    nodes: 1,
    uptime: "99.99%",
    nextUpdate: "2024-03-15",
    lastIncident: "2024-02-01",
    specs: {
      cpu: "64 vCPU",
      ram: "512 GB",
      storage: "4 TB NVMe",
      network: "2 Gbps"
    }
  },
  {
    id: "openledger",
    name: "OpenLedger",
    icon: "⬡",
    type: "Testnet",
    status: "Active",
    nodes: 6482,
    uptime: "99.97%",
    nextUpdate: "2024-03-12",
    lastIncident: "None",
    specs: {
      cpu: "32 vCPU",
      ram: "64 GB",
      storage: "2 TB NVMe",
      network: "1 Gbps"
    }
  },
  {
    id: "ritual",
    name: "Ritual",
    type: "Testnet",
    status: "Monitoring",
    nodes: 2401,
    uptime: "99.95%",
    nextUpdate: "2024-03-10",
    lastIncident: "2024-02-15",
    specs: {
      cpu: "16 vCPU",
      ram: "32 GB",
      storage: "1 TB NVMe",
      network: "500 Mbps"
    }
  }
];

function Projects() {
  const [time, setTime] = useState(new Date());
  const [metrics, setMetrics] = useState({
    cpu: 0,
    memory: 0,
    network: 0
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
      // Симулируем изменение метрик
      setMetrics({
        cpu: Math.floor(Math.random() * 30) + 70, // 70-100%
        memory: Math.floor(Math.random() * 20) + 60, // 60-80%
        network: Math.floor(Math.random() * 40) + 40  // 40-80%
      });
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Active Projects - xNod3</title>
        <meta name="description" content="Current active blockchain nodes and deployments in xNod3 infrastructure" />
      </Helmet>

      <div className="xproj-container">
        <div className="xproj-header">
          <div className="xproj-title-section">
            <div className="xproj-title">
              <span className="xproj-title-prefix">&gt;</span>
              <span className="xproj-command">show active_projects</span>
              <span className="xproj-cursor">_</span>
            </div>
            <div className="xproj-status">
              <span className="xproj-status-dot"></span>
              <span className="xproj-status-value">OPERATIONAL</span>
              <span className="xproj-status-time">{time.toLocaleTimeString()}</span>
            </div>
          </div>
          
          <div className="xproj-metrics">
            <div className="xproj-metric">
              <div className="xproj-metric-bar">
                <div className="xproj-metric-fill" style={{ width: `${metrics.cpu}%` }}></div>
              </div>
              <span className="xproj-metric-label">CPU {metrics.cpu}%</span>
            </div>
            <div className="xproj-metric">
              <div className="xproj-metric-bar">
                <div className="xproj-metric-fill" style={{ width: `${metrics.memory}%` }}></div>
              </div>
              <span className="xproj-metric-label">MEM {metrics.memory}%</span>
            </div>
            <div className="xproj-metric">
              <div className="xproj-metric-bar">
                <div className="xproj-metric-fill" style={{ width: `${metrics.network}%` }}></div>
              </div>
              <span className="xproj-metric-label">NET {metrics.network}%</span>
            </div>
          </div>
        </div>

        <div className="xproj-grid">
          {projects.map(project => (
            <div key={project.id} className="xproj-card">
              <div className="xproj-card-header">
                <div className="xproj-card-title">
                  <span className="xproj-card-icon">{project.icon}</span>
                  <span className="xproj-card-name">{project.name}</span>
                </div>
                <div className={`xproj-card-status ${project.status.toLowerCase()}`}>
                  [ {project.status.toUpperCase()} ]
                </div>
              </div>

              <div className="xproj-card-content">
                <div className="xproj-node-info">
                  <div className="xproj-node-type">
                    <span className="xproj-node-label">{project.type}</span>
                    <span className="xproj-node-count">{project.nodes}</span>
                  </div>
                  <div className="xproj-node-uptime">
                    <span className="xproj-uptime-label">UPTIME</span>
                    <span className="xproj-uptime-value">{project.uptime}</span>
                  </div>
                </div>

                <div className="xproj-specs">
                  <div className="xproj-specs-title">
                    <span className="xproj-specs-icon">⚡</span>
                    <span>SPECIFICATIONS</span>
                  </div>
                  <div className="xproj-specs-grid">
                    <div className="xproj-spec">
                      <span className="xproj-spec-icon">◇</span>
                      <span className="xproj-spec-label">CPU</span>
                      <span className="xproj-spec-value">{project.specs.cpu}</span>
                    </div>
                    <div className="xproj-spec">
                      <span className="xproj-spec-icon">□</span>
                      <span className="xproj-spec-label">RAM</span>
                      <span className="xproj-spec-value">{project.specs.ram}</span>
                    </div>
                    <div className="xproj-spec">
                      <span className="xproj-spec-icon">◈</span>
                      <span className="xproj-spec-label">STORAGE</span>
                      <span className="xproj-spec-value">{project.specs.storage}</span>
                    </div>
                    <div className="xproj-spec">
                      <span className="xproj-spec-icon">↔</span>
                      <span className="xproj-spec-label">NETWORK</span>
                      <span className="xproj-spec-value">{project.specs.network}</span>
                    </div>
                  </div>
                </div>

                <div className="xproj-updates">
                  <div className="xproj-update">
                    <span className="xproj-update-icon">⟳</span>
                    <span className="xproj-update-label">NEXT_UPDATE</span>
                    <span className="xproj-update-value">{project.nextUpdate}</span>
                  </div>
                  <div className="xproj-update">
                    <span className="xproj-update-icon">⚠</span>
                    <span className="xproj-update-label">LAST_INCIDENT</span>
                    <span className="xproj-update-value">
                      {project.lastIncident === 'None' ? 'NO_INCIDENTS' : project.lastIncident}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Projects;
