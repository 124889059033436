// src/pages/Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthTerminal from './AuthTerminal';
import '../styles/DashboardRetro.css';
import api from '../services/api';
import PaymentModal from '../components/PaymentModal';
import TelegramAccess from '../components/TelegramAccess';
import { Helmet } from 'react-helmet-async';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SubscriptionStatus from '../components/SubscriptionStatus';
import EnterpriseTab from '../components/EnterpriseTab';

// Константы уровней доступа
const ACCESS_LEVELS = {
  INIT_0x: {
    name: 'INIT_0x',
    description: 'Basic access level'
  },
  CIPHER_1x: {
    name: 'CIPHER_1x',
    description: 'Advanced access level'
  },
  KERNEL_2x: {
    name: 'KERNEL_2x',
    description: 'Premium access level'
  },
  ROOT_3x: {
    name: 'ROOT_3x',
    description: 'Maximum access level'
  },
  PHANTOM_X: {
    name: 'PHANTOM_X',
    description: 'Enterprise access level'
  }
};

function Dashboard() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState('private');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [paymentURL, setPaymentURL] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [currentUsername, setCurrentUsername] = useState('');
  const [apiKey, setApiKey] = useState(null);
  const [copied, setCopied] = useState(false);
  const [apiKeyLoading, setApiKeyLoading] = useState(false);
  const [apiKeyError, setApiKeyError] = useState(null);

  const menuRef = useRef(null);
  const userContainerRef = useRef(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const response = await api.get('/auth/me');
        if (response.data.user) {
          setIsAuthenticated(true);
          setCurrentUsername(response.data.user.telegram_username);
          await fetchSubscription(token);
        } else {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userContainerRef.current && 
          !userContainerRef.current.contains(event.target) &&
          menuRef.current && 
          !menuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const loadApiKey = async () => {
      if (isAuthenticated && subscription?.status === 'active') {
        await fetchApiKey();
      }
    };
    
    loadApiKey();
  }, [isAuthenticated, subscription]);

  const fetchSubscription = async (token) => {
    try {
      const response = await api.get('/payments', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Subscription response:', response.data);
      if (!response.data.subscription) {
        setSubscription({ status: 'new' });
      } else {
        setSubscription(response.data.subscription);
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
      setSubscription({ status: 'new' });
    }
  };

  const handleDisconnect = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setCurrentUsername('');
    setSubscription(null);
    setShowUserMenu(false);
  };

  const handleMouseEnter = () => {
    setShowUserMenu(true);
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    if (!menuRef.current?.contains(relatedTarget) && 
        !userContainerRef.current?.contains(relatedTarget)) {
      setShowUserMenu(false);
    }
  };

  const handleTabChange = (tab) => {
    setLoading(true);
    setActiveTab(tab);
    setTimeout(() => setLoading(false), 300);
  };

  const handleSubscriptionManage = () => {
    setActiveModal('subscription');
  };

  const handleRenewal = () => {
    setShowPaymentModal(true);
  };

  const handleGuideOpen = (guideId) => {
    // Проверк доступа к гайду
    if (!subscription || subscription.status !== 'active') {
      setError('Active subscription required');
      return;
    }
    setActiveModal(`guide-${guideId}`);
  };

  const handleEnterpriseRequest = async () => {
    setLoading(true);
    try {
      // Здесь будет API запрос для запроса Enterprise достпа
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubscription(prev => ({
        ...prev,
        type: 'PHANTOM_X',
        status: 'active',
        expiresAt: '2025-12-31',
        features: ['guides', 'news', 'priority-support', 'custom-solutions']
      }));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchApiKey = async () => {
    try {
      setApiKeyLoading(true);
      setApiKeyError(null);
      const response = await api.get('/api-keys/current');
      setApiKey(response.data.api_key);
    } catch (err) {
      setApiKeyError('Ошибка при получении API ключа');
      console.error('API key fetch error:', err);
    } finally {
      setApiKeyLoading(false);
    }
  };

  const generateNewApiKey = async () => {
    try {
      setApiKeyLoading(true);
      setApiKeyError(null);
      const response = await api.post('/api-keys/generate');
      setApiKey(response.data.api_key);
    } catch (err) {
      setApiKeyError('Ошибка при генерации API ключа');
      console.error('API key generation error:', err);
    } finally {
      setApiKeyLoading(false);
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  // Компонент модального окна
  const Modal = ({ children, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
        <button className="modal-close" onClick={onClose}>[x]</button>
      </div>
    </div>
  );

// Обработчик создания платежа
const handlePay = async (months) => {
  try {
    setLoading(true);
    setError(null);
    
    const response = await api.post(
      '/payments/create',
      { months },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data?.payment?.url) {
      window.location.href = response.data.payment.url;
    } else {
      setError('Ошибка при создании платежа');
    }
  } catch (err) {
    console.error('Ошибка оплаты:', err);
    setError(err.response?.data?.message || 'Ошибка при создании платежа');
  } finally {
    setLoading(false);
  }
};

const handleSubscribe = () => {
  handlePay(); // Используем существующую функцию для создания платежа
};

const handlePaymentSubmit = async (months) => {
  try {
    setLoading(true);
    setError(null);
    setShowPaymentModal(false);
    
    const response = await api.post(
      '/payments/create',
      { months },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data?.payment?.url) {
      window.location.href = response.data.payment.url;
    } else {
      setError('Ошибка при создании платежа');
    }
  } catch (err) {
    console.error('Ошибка оплаты:', err);
    setError(err.response?.data?.message || 'Ошибка при создании платежа');
  } finally {
    setLoading(false);
  }
};

const GuidesSection = () => {
  const guides = [
    { id: '01', name: 'Deployment_guide.txt', size: '12.4KB', date: '2024-01-15' },
    { id: '02', name: 'Setup_instructions.txt', size: '8.2KB', date: '2024-01-14' },
    { id: '03', name: 'API_documentation.txt', size: '24.6KB', date: '2024-01-13' },
    { id: '04', name: 'Security_best_practices.txt', size: '16.8KB', date: '2024-01-12' }
  ];

  return (
    <div className="xnd-guides-section">
      <div className="xnd-guides-header">
        Directory listing for /guides/
        <div className="xnd-guides-separator">
          ===============================
        </div>
      </div>
      <ul className="xnd-guides-list">
        {guides.map((guide) => (
          <li key={guide.id} className="xnd-guide-item">
            [{guide.id}] {guide.name}
            <span className="xnd-guide-meta">
              {guide.size} | {guide.date}
            </span>
          </li>
        ))}
      </ul>
      <div className="xnd-guides-footer">
        {guides.length} File(s) | Press [ENTER] to view
      </div>
    </div>
  );
};

  // Если не аутентифицирован, показываем терминал входа
  if (!isAuthenticated) {
    return <AuthTerminal onAuthenticated={(username) => {
      setIsAuthenticated(true);
      setCurrentUsername(username);
      fetchSubscription(localStorage.getItem('token'));
    }} />;
  }

  return (
    <>
      <Helmet>
        <title>xNod3 Dashboard</title>
        <meta name="description" content="Manage your nodes and subscriptions in the xNod3 dashboard" />
      </Helmet>

      {/* Эффект шума и сканлайн */}
      <div className="xnd-noise-overlay" />
      <div className="xnd-scanlines" />
      
      {/* Матричный фон */}
      <div className="xnd-matrix-background">
        {Array.from({ length: 20 }).map((_, i) => (
          <div 
            key={i}
            className="xnd-matrix-line"
            style={{
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 10 + 5}s`,
              animationDelay: `${Math.random() * 5}s`
            }}
          >
            {Array.from({ length: 50 }).map(() => 
              Math.random() > 0.5 ? '1' : '0'
            ).join('')}
          </div>
        ))}
      </div>

      <div className="xnd-dashboard-container xnd-matrix-bg">
        <div className="xnd-dashboard-window xnd-crt-effect">
          {/* Заголовок */}
          <div className="xnd-dashboard-header">
            <div className="xnd-dashboard-left">
              <div className="xnd-dashboard-controls">
                <div className="xnd-control red"></div>
                <div className="xnd-control yellow"></div>
                <div className="xnd-control green"></div>
              </div>
            </div>
            
            <div className="xnd-dashboard-title xnd-text-glitch" data-text="xNod3_DASHBOARD [V1.0]">
              xNod3_DASHBOARD [V1.0]
            </div>
            
            <div className="xnd-dashboard-right">
              <div className="xnd-user-block">
                <span className="xnd-user-label">[USER]</span>
                <span className="xnd-username xnd-pixel-effect">{currentUsername}</span>
              </div>
              <button 
                className="xnd-disconnect-button xnd-hover-glow" 
                onClick={handleDisconnect}
              >
                [×] DISCONNECT
              </button>
            </div>
          </div>

          {/* Вкладки */}
          <div className="xnd-tabs">
            <button 
              className={`xnd-tab ${activeTab === 'private' ? 'active' : ''}`}
              onClick={() => handleTabChange('private')}
            >
              [{'>'} ] Private Access
            </button>
            <button 
              className={`xnd-tab ${activeTab === 'enterprise' ? 'active' : ''}`}
              onClick={() => handleTabChange('enterprise')}
            >
              [{'>'} ] Enterprise Solutions
            </button>
          </div>

          {/* Основной контент */}
          <div className="xnd-dashboard-content">
            {loading ? (
              <div className="xnd-loading-container">
                <div className="xnd-loading-dots">
                  <div className="xnd-loading-dot"></div>
                  <div className="xnd-loading-dot"></div>
                  <div className="xnd-loading-dot"></div>
                </div>
                <div className="xnd-terminal-text">SCANNING_SYSTEM...</div>
              </div>
            ) : error ? (
              <div className="xnd-error-message">
                <span className="xnd-error-icon">[!]</span>
                <span className="xnd-error-text">{error}</span>
              </div>
            ) : (
              <div className={`xnd-tab-transition ${!loading ? 'active' : ''}`}>
                {activeTab === 'enterprise' ? (
                  <EnterpriseTab />
                ) : (
                  <div className="xnd-private-dashboard">
                    {/* Блок статуса подписки */}
                    <div className="xnd-status-block xnd-subscription">
                      <div className="xnd-block-header">
                        <h3 className="xnd-block-title">[{'>'} ] Subscription Status</h3>
                      </div>
                      <div className="xnd-status-content">
                        {!subscription ? (
                          <div className="xnd-loading-container">
                            <div className="xnd-loading-dots">
                              <div className="xnd-loading-dot"></div>
                              <div className="xnd-loading-dot"></div>
                              <div className="xnd-loading-dot"></div>
                            </div>
                            <div className="xnd-terminal-text">SCANNING_SUBSCRIPTION...</div>
                          </div>
                        ) : subscription.status === 'new' ? (
                          <div className="xnd-welcome-block">
                            <div className="xnd-status-row">
                              <span className="xnd-status-label">[STATUS]</span>
                              <span className="xnd-status-value">NEW_USER</span>
                            </div>
                            <div className="xnd-welcome-message">
                              Welcome to xNod3! To access private features, you need an active subscription.
                            </div>
                            <div className="xnd-action-buttons">
                              <Link to="/private" className="xnd-info-button">
                                [?] ABOUT PRIVATE ACCESS
                              </Link>
                              <button 
                                className="xnd-subscribe-button" 
                                onClick={handleRenewal}
                              >
                                [+] GET ACCESS NOW
                              </button>
                            </div>
                          </div>
                        ) : (
                          <SubscriptionStatus 
                            subscription={subscription} 
                            onRenew={handleRenewal}
                          />
                        )}
                        {showPaymentModal && (
                          <PaymentModal 
                            onClose={() => setShowPaymentModal(false)} 
                            onSubmit={handlePaymentSubmit}
                          />
                        )}
                      </div>
                    </div>

                    {/* Блок API ключа */}
                    {subscription?.status === 'active' && (
                      <div className="xnd-status-block xnd-api">
                        <div className="xnd-block-header">
                          <h3 className="xnd-block-title">[{'>'} ] API Access</h3>
                        </div>
                        <div className="xnd-status-content">
                          <div className="xnd-api-key-row">
                            <span className="xnd-key-icon">[🔑]</span>
                            <span className="xnd-api-key-text">
                              {apiKey || 'NOT_GENERATED'}
                            </span>
                            {apiKey && (
                              <CopyToClipboard text={apiKey} onCopy={handleCopy}>
                                <button className={`xnd-key-action xnd-copy ${copied ? 'copied' : ''}`}>
                                  {copied ? '[✓]' : '[⎘]'}
                                </button>
                              </CopyToClipboard>
                            )}
                          </div>

                          <button 
                            className="xnd-api-key-button"
                            onClick={generateNewApiKey}
                            disabled={apiKeyLoading}
                          >
                            {apiKeyLoading ? '[⟳] GENERATING...' : 
                             apiKey ? '[↻] GENERATE NEW' : '[+] GENERATE KEY'}
                          </button>

                          <div className="xnd-api-key-warning">
                            <span className="xnd-warning-icon">[!]</span>
                            <span>Keep your API key secure. Generating a new key invalidates the old one.</span>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Блок Telegram */}
                    <div className="xnd-status-block xnd-telegram">
                      <div className="xnd-block-header">
                        <h3 className="xnd-block-title">[{'>'} ] Telegram Access</h3>
                      </div>
                      <div className="xnd-status-content">
                        <div className="xnd-telegram-status">
                          <div className="xnd-telegram-connection">
                            Connected to private chat
                          </div>
                          <div className="xnd-telegram-info">
                            <span>@{currentUsername}</span>
                            <span>Last sync: {new Date().toLocaleDateString()}</span>
                            <span className="xnd-status-value active">ONLINE</span>
                          </div>
                        </div>
                        <TelegramAccess subscription={subscription} />
                      </div>
                    </div>

                    {/* Навигационная сетка */}
                    <div className="xnd-navigation-grid">
                      <Link to="/guides" className="xnd-nav-card xnd-hover-glow">
                        <div className="xnd-nav-card-content">
                          <div className="xnd-nav-card-icon">[📚]</div>
                          <h3 className="xnd-nav-card-title">Guides</h3>
                          <GuidesSection />
                        </div>
                      </Link>

                      <Link to="/news" className="xnd-nav-card xnd-hover-glow">
                        <div className="xnd-nav-card-content">
                          <div className="xnd-nav-card-icon">[📰]</div>
                          <h3 className="xnd-nav-card-title">News</h3>
                          <p className="xnd-nav-card-description">
                            Latest updates and announcements
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Модальное окно оплаты */}
        {showPaymentModal && (
          <div className="xnd-modal-overlay">
            <div className="xnd-modal-content">
              <div className="xnd-modal-header">
                <h3 className="xnd-modal-title">[{'>'} ] Subscription Management</h3>
                <button 
                  className="xnd-modal-close"
                  onClick={() => setShowPaymentModal(false)}
                >
                  [×]
                </button>
              </div>
              <PaymentModal 
                onClose={() => setShowPaymentModal(false)}
                onSubmit={handlePaymentSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
