import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const defaultBaseURL = isDevelopment ? 'http://localhost:5055/api' : '/api';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || defaultBaseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Добавляем перехватчик запросов
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Добавляем перехватчик ответов
api.interceptors.response.use(
  response => response,
  error => {
    // Проверяем, является ли текущий URL публичным маршрутом
    const publicRoutes = ['/auth/verify_signature', '/auth/request_nonce'];
    const isPublicRoute = publicRoutes.some(route => 
      error.config.url.includes(route)
    );

    // Делаем редирект только если это не публичный маршрут
    if (error.response?.status === 401 && !isPublicRoute) {
      // Не делаем редирект, только очищаем токен
      localStorage.removeItem('token');
      // window.location.href = '/'; // Убираем автоматический редирект
    }
    return Promise.reject(error);
  }
);

export default api;