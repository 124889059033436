// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AdminLink from './AdminLink';
import '../styles/Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { isAdmin } = useAuth();
  
  const mainMenuItems = [
    { path: '/', icon: '~', text: 'HOME' },
    { path: '/infrastructure', icon: '⬡', text: 'INFRASTRUCTURE' },
    { path: '/guides', icon: '⌘', text: 'KNOWLEDGE' },
    { path: '/dashboard', icon: '⚙', text: 'DASHBOARD' },
  ];

  const infoMenuItems = [
    { path: '/about', icon: '⌥', text: 'ABOUT' },
    { path: '/contact', icon: '⌬', text: 'CONTACT' },
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <header className="header-container">
      <div className="header-content">
        <Link to="/" className="logo-link">
          <img src="/images/xnod3-logo-new.svg" alt="xNod3" className="logo-image" />
        </Link>

        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="nav-section">
            {mainMenuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`nav-link ${isActive(item.path) ? 'active' : ''}`}
              >
                <span className="menu-icon">{item.icon}</span>
                {item.text}
              </Link>
            ))}
            <AdminLink />
          </div>

          <div className="nav-section">
            {infoMenuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`nav-link ${isActive(item.path) ? 'active' : ''}`}
              >
                <span className="menu-icon">{item.icon}</span>
                {item.text}
              </Link>
            ))}
          </div>
        </nav>

        <button
          className="menu-toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? '[×]' : '[≡]'}
        </button>
      </div>
    </header>
  );
}

export default Header;
