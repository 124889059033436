import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './AuthContext';

const EnterpriseContext = createContext();

export function EnterpriseProvider({ children }) {
  const { user } = useAuth();
  const [enterpriseData, setEnterpriseData] = useState({
    servers: [],
    wallets: [],
    userAccess: null
  });

  return (
    <EnterpriseContext.Provider value={{ enterpriseData, setEnterpriseData, userId: user?.id }}>
      {children}
    </EnterpriseContext.Provider>
  );
}

export const useEnterprise = () => useContext(EnterpriseContext); 