import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import api from '../services/api';
import '../styles/GuidesRetro.css';

function GuidesPage() {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('guides-active-tab') || 'all';
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTabsSticky, setIsTabsSticky] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');
  const tabsRef = useRef(null);
  const tabsWrapperRef = useRef(null);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await api.get('/guides');
        setGuides(response.data);
        setSystemMessage('[SUCCESS] Guides database loaded successfully.');
      } catch (err) {
        setError('Ошибка при загрузке гайдов');
        setSystemMessage('[ERROR] Failed to access guides database.');
      } finally {
        setLoading(false);
      }
    };

    setSystemMessage('[SYSTEM] Initializing guides database...');
    fetchGuides();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current && tabsWrapperRef.current) {
        const { top } = tabsWrapperRef.current.getBoundingClientRect();
        setIsTabsSticky(top <= 60);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSystemMessage(`[SYSTEM] Accessing directory /${tab}...`);
    localStorage.setItem('guides-active-tab', tab);
  };

  const formatFileSize = (size) => {
    return `${(size / 1024).toFixed(1)} KB`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const filteredGuides = guides
    .filter(guide => {
      if (!guide.is_active) return false;

      const matchesSearch = 
        guide.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        guide.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        guide.tags?.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));
      
      const matchesCategory = activeTab === 'all' || 
        (guide.category && guide.category.toLowerCase() === activeTab.toLowerCase()) ||
        (guide.tags && guide.tags.includes(activeTab));
      
      return matchesSearch && matchesCategory;
    })
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const renderAsciiArt = (type) => {
    switch (type) {
      case 'hyperlane':
        return `╔═══╗\n║ H ║\n╚═══╝`;
      case 'sonaric':
        return `┌───┐\n│ S │\n└───┘`;
      case 'sixgpt':
        return `▄███▄\n█ G █\n▀███▀`;
      case 'elixir':
        return `╭───╮\n│ E │\n╰───╯`;
      case 'volera':
        return `▒▒▒▒▒\n▒ V ▒\n▒▒▒▒▒`;
      case 'nexus':
        return `╱╲╱╲\n╲ N ╱\n╱╲╱╲`;
      case 'mesa':
        return `[═M═]`;
      case 'nomi':
        return `╔═N═╗`;
      case 'waku':
        return `◢═W═◣`;
      case 'ritual':
        return `┏━R━┓`;
      default:
        return `[${type?.[0]?.toUpperCase() || '?'}]`;
    }
  };

  const formatPermissions = (guide) => {
    const read = guide.is_public ? 'r' : '-';
    const write = guide.is_premium ? 'w' : '-';
    const exec = guide.is_hot ? 'x' : '-';
    return `[${read}${write}${exec}]`;
  };

  const formatSystemInfo = (guide) => {
    const owner = guide.is_premium ? 'root' : 'user';
    const group = guide.is_public ? 'public' : 'private';
    return `${owner}:${group}`;
  };

  const GuidesList = ({ guides }) => {
    return (
      <div className="guides-list">
        <div className="guides-list-header-row">
          <div className="guides-list-header-cell">Тип</div>
          <div className="guides-list-header-cell">Название</div>
          <div className="guides-list-header-cell">Размер</div>
          <div className="guides-list-header-cell">Изменен</div>
          <div className="guides-list-header-cell">Статус</div>
          <div className="guides-list-header-cell"></div>
        </div>
        {guides.map((guide, index) => (
          <div 
            key={guide.id || index} 
            className={`guides-list-item ${!guide.is_public ? 'masked' : ''}`}
            data-id={`0x${(index + 1).toString(16).padStart(4, '0')}`}
            data-category={guide.category || 'other'}
          >
            <div className="guides-list-icon">
              <div className="ascii-art" data-ascii={renderAsciiArt(guide.type)}>
                {formatPermissions(guide)}
              </div>
              <div className="system-info">
                {formatSystemInfo(guide)}
              </div>
            </div>
            <div className="guides-list-text">
              <div className="guides-list-item-title">
                {`${(index + 1).toString().padStart(2, '0')}_${guide.title}.gde`}
                {guide.is_hot && <span className="guides-list-badge hot">HOT</span>}
                {!guide.is_public && <span className="guides-list-badge private">PRIVATE</span>}
                {guide.is_premium && <span className="guides-list-badge premium">PREMIUM</span>}
              </div>
              <div className="guides-list-description">
                {guide.description}
                <span className="file-info">
                  {`<${formatFileSize(guide.size || 12400)} | ${formatDate(guide.created_at)}>`}
                </span>
              </div>
            </div>
            <div className="guides-list-meta">{formatFileSize(guide.size || 12400)}</div>
            <div className="guides-list-meta">{formatDate(guide.created_at)}</div>
            <div className="guides-list-meta">{guide.status?.toUpperCase() || 'STANDARD'}</div>
            <Link 
              to={`/guides/${guide.slug}`} 
              className="guides-list-button"
              onClick={() => {
                setSystemMessage(`[SYSTEM] Opening guide: ${guide.title}...`);
                console.log(`ls -l ${guide.title}.gde\n${formatPermissions(guide)} ${formatSystemInfo(guide)} ${formatFileSize(guide.size || 12400)} ${formatDate(guide.created_at)} ${guide.title}.gde`);
              }}
            >
              {'[OPEN]>'}
            </Link>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="guides-loading">
        <div>LOADING DATABASE...</div>
        <div className="guides-loading-progress">
          {Array(3).fill().map((_, i) => (
            <span key={i} style={{ animationDelay: `${i * 0.2}s` }}>.</span>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="guides-error">
        [ERROR] {error}
        <div className="guides-error-details">
          {'>'} Try refreshing the page or contact system administrator.
        </div>
      </div>
    );
  }

  return (
    <div className="guides-list-page">
      <Helmet>
        <title>Guides Database - xNod3</title>
        <meta name="description" content="Access the xNod3 guides database" />
      </Helmet>

      <div className="guides-list-header">
        <h1 className="guides-list-title">GUIDES_DATABASE v1.0</h1>
        <p className="guides-list-description">{systemMessage}</p>
      </div>

      <div className="guides-list-search-bar">
        <input
          type="text"
          placeholder="INIT SEARCH SEQUENCE..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSystemMessage(`[SEARCH] Scanning for "${e.target.value}"...`);
          }}
          className="guides-list-search-input"
        />
      </div>

      <div className="guides-list-tabs-wrapper" ref={tabsWrapperRef}>
        <div className={`guides-list-tabs ${isTabsSticky ? 'sticky' : ''}`} ref={tabsRef}>
          {['all', 'nodes', 'tools', 'helpful'].map((tab) => (
            <button
              key={tab}
              className={`guides-list-tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => handleTabChange(tab)}
            >
              [DIR] /{tab.toUpperCase()}
              {isTabsSticky && (
                <span className="tab-status">
                  {activeTab === tab ? 'ACTIVE' : 'IDLE'}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>

      <GuidesList guides={filteredGuides} />
    </div>
  );
}

export default GuidesPage;
