// src/App.js
import React, { useState } from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Dashboard from './pages/Dashboard';
import EnterprisePage from './pages/EnterprisePage';
import PrivateAccess from './pages/PrivateAccess';
import AboutPage from './pages/AboutPage';
import AuthTerminal from './pages/AuthTerminal';
import GuidesPage from './pages/GuidesPage';
import ConfigTerminal from './pages/ConfigTerminal';
import GuidePage from './pages/GuidePage';
import './App.css';
import './styles/common/TerminalWindow.css';
import CommandLineLoader from './components/CommandLineLoader';
import AdminRoute from './components/AdminRoute';
import AdminGuidesPage from './pages/AdminGuidesPage';
import GuideEditorPage from './pages/GuideEditorPage';
import { AuthProvider } from './contexts/AuthContext';
import './styles/animations.css';
import NewsEditorPage from './pages/NewsEditorPage';
import AdminNewsPage from './pages/AdminNewsPage';
import AdminPage from './pages/AdminPage';
import AdminSettings from './pages/AdminSettings';
import NewsPage from './pages/NewsPage';
import NewsViewPage from './pages/NewsViewPage';
import { LanguageProvider } from './contexts/LanguageContext';
import WikiEditorPage from './pages/WikiEditorPage';
import AdminWikiPage from './pages/AdminWikiPage';
import NotFoundPage from './pages/NotFoundPage';
import Footer from './components/Footer';
import './styles/Layout.css';
import AdminTelegramPage from './pages/AdminTelegramPage';
import AdminProjects from './pages/admin/Projects';
import AsciiLoader from './components/AsciiLoader';
import AdminEnterprisePage from './pages/admin/AdminEnterprisePage';
import { EnterpriseProvider } from './contexts/EnterpriseContext';
import Infrastructure from './pages/Infrastructure';

// Выбираем роутер на основе переменной окружения
const Router = process.env.REACT_APP_ROUTER_TYPE === 'browser' ? BrowserRouter : HashRouter;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LanguageProvider>
      <AuthProvider>
        <EnterpriseProvider>
          <HelmetProvider>
            <Router>
              <div className="app-container">
                {isLoading ? (
                  <AsciiLoader onLoadingComplete={() => setIsLoading(false)} />
                ) : (
                  <div className="main-content">
                    <Header />
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/infrastructure" element={<Infrastructure />} />
                      <Route path="/projects" element={<Projects />} />
                      <Route path="/guides" element={<GuidesPage />} />
                      <Route path="/guides/:slug" element={<GuidePage />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/auth-terminal" element={<AuthTerminal />} />
                      <Route path="/config" element={<ConfigTerminal />} />
                      <Route path="/admin" element={<AdminPage />}>
                        <Route path="guides" element={<AdminGuidesPage />} />
                        <Route path="projects" element={<AdminProjects />} />
                        <Route path="guides/new" element={<GuideEditorPage />} />
                        <Route path="guides/edit/:slug" element={<GuideEditorPage />} />
                        <Route path="news" element={<AdminNewsPage />} />
                        <Route path="news/create" element={<NewsEditorPage />} />
                        <Route path="news/edit/:slug" element={<NewsEditorPage />} />
                        <Route path="settings" element={<AdminSettings />} />
                        <Route path="wiki" element={<AdminWikiPage />} />
                        <Route path="wiki/new" element={<WikiEditorPage />} />
                        <Route path="wiki/edit/:slug" element={<WikiEditorPage />} />
                        <Route path="telegram" element={<AdminTelegramPage />} />
                        <Route path="enterprise" element={<AdminEnterprisePage />} />
                      </Route>
                      <Route path="/private" element={<PrivateAccess />} />
                      <Route path="/enterprise" element={<EnterprisePage />} />
                      <Route path="/news" element={<NewsPage />} />
                      <Route path="news/:slug" element={<NewsViewPage />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <Footer />
                  </div>
                )}
              </div>
            </Router>
          </HelmetProvider>
        </EnterpriseProvider>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
