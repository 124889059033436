import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import '../../styles/admin/AdminEnterprise.css';
import ServerModal from '../../components/modals/ServerModal';
import WalletModal from '../../components/modals/WalletModal';
import NodeModal from '../../components/modals/NodeModal';
import LinkNodesModal from '../../components/modals/LinkNodesModal';

// Функция для чтения JSON файла
const readFileAsJson = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        const data = JSON.parse(event.target.result);
        resolve(data);
      } catch (error) {
        reject(new Error('Invalid JSON file'));
      }
    };
    
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };
    
    reader.readAsText(file);
  });
};

function AdminEnterprisePage() {
  // Состояние для списка пользователей и выбранного пользователя
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  
  const [data, setData] = useState({ wallets: [], servers: [] });
  const [types, setTypes] = useState({ wallet_types: [], node_types: [], networks: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Состояния для модальных окон
  const [showServerModal, setShowServerModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showNodeModal, setShowNodeModal] = useState(false);
  const [showLinkNodesModal, setShowLinkNodesModal] = useState(false);
  const [showAddNodesToAllModal, setShowAddNodesToAllModal] = useState(false);
  const [showBulkStatusModal, setShowBulkStatusModal] = useState(false);
  
  // Состояния для форм
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [actionStatus, setActionStatus] = useState(null);

  useEffect(() => {
    // При монтировании компонента загружаем все необходимые данные
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const [typesResponse, statsResponse, usersResponse] = await Promise.all([
          api.get('/enterprise/types'),
          api.get('/enterprise/status'),
          api.get('/users')
        ]);
        
        setTypes(typesResponse.data);
        setUsers(usersResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(err.message || 'Failed to load data');
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // При выборе пользователя загружаем его данные
  useEffect(() => {
    if (selectedUserId) {
      fetchUserData(selectedUserId);
    }
  }, [selectedUserId]);

  // Загрузка данных конкретного пользователя
  const fetchUserData = async (userId) => {
    try {
      const response = await api.get(`/enterprise/user/${userId}`);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load user data');
      setLoading(false);
    }
  };

  // Обновим функцию добавления сервера
  const handleAddServer = async (serverData) => {
    try {
      setActionStatus('loading');
      // Добавляем userId к данным сервера
      const response = await api.post('/enterprise/servers', {
        ...serverData,
        userId: selectedUserId
      });
      
      if (response.data) {
        await fetchUserData(selectedUserId);
        setShowServerModal(false);
        setActionStatus('success');
        setTimeout(() => setActionStatus(null), 3000);
      }
    } catch (error) {
      console.error('Error adding server:', error);
      setActionStatus('error');
      setError('Failed to add server');
    }
  };

  // Обновим функцию добавления кошелька
  const handleAddWallet = async (walletData) => {
    try {
      setActionStatus('loading');
      
      // Логируем данные перед отправкой
      console.log('Sending wallet data:', {
        ...walletData,
        userId: selectedUserId,
        private_key: '[HIDDEN]'
      });
      
      const response = await api.post('/enterprise/wallets', {
        ...walletData,
        userId: selectedUserId
      });
      
      if (response.data) {
        await fetchUserData(selectedUserId);
        setShowWalletModal(false);
        setActionStatus('success');
        setTimeout(() => setActionStatus(null), 3000);
      }
    } catch (error) {
      console.error('Error adding wallet:', error.response?.data || error);
      setActionStatus('error');
      if (error.response?.data?.message) {
        setError(error.response?.data?.message);
      } else if (error.response?.data?.sqlMessage) {
        setError(error.response?.data?.sqlMessage);
      } else {
        setError('Failed to add wallet');
      }
      setTimeout(() => setError(null), 3000);
    }
  };

  // Функции для работы с серверами
  const addServer = async (serverData) => {
    if (!selectedUserId) {
      setError('Please select a user first');
      return;
    }
    try {
      await api.post('/enterprise/servers', {
        ...serverData,
        userId: selectedUserId
      });
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to add server');
    }
  };

  const deleteServer = async (serverId) => {
    try {
      await api.delete(`/enterprise/servers/${serverId}`);
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to delete server');
    }
  };

  const updateServerStatus = async (serverId, status) => {
    try {
      await api.patch(`/enterprise/servers/${serverId}/status`, { status });
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to update server status');
    }
  };

  // Функции для работы с нодами
  const addNode = async (serverId, nodeType) => {
    try {
      await api.post(`/enterprise/servers/${serverId}/nodes`, { type: nodeType });
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to add node');
    }
  };

  const deleteNode = async (serverId, nodeId) => {
    try {
      await api.delete(`/enterprise/servers/${serverId}/nodes/${nodeId}`);
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to delete node');
    }
  };

  // Функции для работы с кошельками
  const addWallet = async (walletData) => {
    try {
      await api.post('/enterprise/wallets', walletData);
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to add wallet');
    }
  };

  const deleteWallet = async (walletId) => {
    try {
      await api.delete(`/enterprise/wallets/${walletId}`);
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to delete wallet');
    }
  };

  const linkNodeToWallet = async (walletId, nodeId, action) => {
    try {
      await api.post(`/enterprise/wallets/${walletId}/nodes`, { nodeId, action });
      fetchUserData(selectedUserId);
    } catch (err) {
      setError('Failed to link node to wallet');
    }
  };

  // Обновим функцию добавления ноды
  const handleAddNode = async (serverId) => {
    try {
      setSelectedServer(serverId);
      setShowNodeModal(true);
    } catch (error) {
      setError('Failed to prepare node addition');
    }
  };

  // Для связывания нод с кошельком
  const handleLinkNodes = async (walletId) => {
    try {
      setSelectedWallet(walletId);
      setShowLinkNodesModal(true);
    } catch (error) {
      setError('Failed to prepare node linking');
    }
  };

  // Обработчик подтверждения добавления ноды
  const handleNodeSubmit = async (nodeType) => {
    try {
      setActionStatus('loading');
      const response = await api.post(`/enterprise/servers/${selectedServer}/nodes`, {
        type: nodeType,
        userId: selectedUserId
      });
      
      // Проверяем успешность запроса
      if (response.status === 200) {
        await fetchUserData(selectedUserId);
        setShowNodeModal(false);
        setActionStatus('success');
        setTimeout(() => setActionStatus(null), 3000);
      }
    } catch (error) {
      console.error('Error adding node:', error);
      setActionStatus('error');
      // Показываем сообщение об ошибке с бэкенда
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError('Failed to add node');
      }
      // Не закрываем модальное окно при ошибке
      setTimeout(() => setError(null), 3000);
    }
  };

  // Обработчик подтверждения связывания нод
  const handleLinkNodesSubmit = async (nodeIds) => {
    try {
      setActionStatus('loading');
      const response = await api.post(`/enterprise/wallets/${selectedWallet}/nodes`, {
        nodeIds,
        userId: selectedUserId
      });
      
      if (response.data) {
        await fetchUserData(selectedUserId);
        setShowLinkNodesModal(false);
        setActionStatus('success');
        setTimeout(() => setActionStatus(null), 3000);
      }
    } catch (error) {
      console.error('Error linking nodes:', error);
      setActionStatus('error');
      setError('Failed to link nodes');
    }
  };

  // Добавляем функции для массового импорта
  const handleBulkImportServers = async (file) => {
    try {
      setActionStatus('loading');
      const data = await readFileAsJson(file);
      
      // Проверяем формат данных
      if (!Array.isArray(data.servers)) {
        throw new Error('Invalid file format. Expected { servers: [...] }');
      }
      
      const response = await api.post('/enterprise/bulk/servers', {
        servers: data.servers,
        userId: selectedUserId
      });
      
      await fetchUserData(selectedUserId);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error importing servers:', error);
      setActionStatus('error');
      setError(error.message || 'Failed to import servers');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleBulkImportWallets = async (file) => {
    try {
      setActionStatus('loading');
      const data = await readFileAsJson(file);
      
      // Проверяем формат данных
      if (!Array.isArray(data.wallets)) {
        throw new Error('Invalid file format. Expected { wallets: [...] }');
      }
      
      const response = await api.post('/enterprise/bulk/wallets', {
        wallets: data.wallets,
        userId: selectedUserId
      });
      
      await fetchUserData(selectedUserId);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error importing wallets:', error);
      setActionStatus('error');
      setError(error.message || 'Failed to import wallets');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Добавляем функцию для добавления нод ко всем серверам
  const handleAddNodesToAllServers = async (nodeType) => {
    try {
      setActionStatus('loading');
      const response = await api.post('/enterprise/nodes/add-to-all', {
        type_id: nodeType,
        userId: selectedUserId
      });
      
      await fetchUserData(selectedUserId);
      setShowAddNodesToAllModal(false);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error adding nodes:', error);
      setActionStatus('error');
      setError(error.response?.data?.message || 'Failed to add nodes');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Добавляем функцию для массового обновления статуса
  const handleBulkUpdateNodeStatus = async (status) => {
    try {
      setActionStatus('loading');
      const response = await api.post('/enterprise/nodes/update-all-status', {
        status,
        userId: selectedUserId
      });
      
      await fetchUserData(selectedUserId);
      setShowBulkStatusModal(false);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error updating nodes status:', error);
      setActionStatus('error');
      setError(error.response?.data?.message || 'Failed to update nodes status');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Добавляем функцию для обновления статуса отдельной ноды
  const handleNodeStatusUpdate = async (serverId, nodeId, status) => {
    try {
      setActionStatus('loading');
      const response = await api.patch(
        `/enterprise/servers/${serverId}/nodes/${nodeId}/status`,
        {
          status: status === 'start' ? 'running' : 'stopped',
          userId: selectedUserId
        }
      );
      
      await fetchUserData(selectedUserId);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error updating node status:', error);
      setActionStatus('error');
      setError(error.response?.data?.message || 'Failed to update node status');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Добавляем функцию для обновления статуса сервера
  const handleServerStatusUpdate = async (serverId, status) => {
    try {
      setActionStatus('loading');
      const response = await api.patch(`/enterprise/servers/${serverId}/status`, {
        status,
        userId: selectedUserId
      });
      
      await fetchUserData(selectedUserId);
      setActionStatus('success');
      setTimeout(() => setActionStatus(null), 3000);
    } catch (error) {
      console.error('Error updating server status:', error);
      setActionStatus('error');
      setError(error.response?.data?.message || 'Failed to update server status');
      setTimeout(() => setError(null), 3000);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="enterprise-container">
      <div className="enterprise-header">
        <h1>[🏢] Enterprise Infrastructure Management</h1>
        <div className="enterprise-user-selector">
          {loading ? (
            <div className="enterprise-loading">[Loading users...]</div>
          ) : error ? (
            <div className="enterprise-error">[Failed to load users]</div>
          ) : users.length === 0 ? (
            <div className="enterprise-empty">[No enterprise users found]</div>
          ) : (
            <select 
              value={selectedUserId || ''} 
              onChange={(e) => setSelectedUserId(e.target.value)}
            >
              <option value="">Select User</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.telegram_username || 'Unnamed User'}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="enterprise-header-actions">
          <input
            type="file"
            accept=".json"
            onChange={(e) => handleBulkImportServers(e.target.files[0])}
            style={{ display: 'none' }}
            id="server-import"
          />
          <label htmlFor="server-import" className="enterprise-action-btn">
            [↑] Import Servers
          </label>
          
          <input
            type="file"
            accept=".json"
            onChange={(e) => handleBulkImportWallets(e.target.files[0])}
            style={{ display: 'none' }}
            id="wallet-import"
          />
          <label htmlFor="wallet-import" className="enterprise-action-btn">
            [↑] Import Wallets
          </label>
          
          <button onClick={() => setShowServerModal(true)} className="enterprise-action-btn">
            [+] Add Server
          </button>
          <button onClick={() => setShowWalletModal(true)} className="enterprise-action-btn">
            [+] Add Wallet
          </button>
          <button 
            onClick={() => setShowAddNodesToAllModal(true)}
            className="enterprise-action-btn"
          >
            [+] Add Nodes to All Servers
          </button>
          <button 
            onClick={() => setShowBulkStatusModal(true)}
            className="enterprise-action-btn"
          >
            [⚡] Update All Nodes Status
          </button>
        </div>
      </div>

      {error && <div className="enterprise-error">{error}</div>}

      {loading ? (
        <div className="enterprise-loading">
          <div className="enterprise-loading-text">[Loading...]</div>
        </div>
      ) : !selectedUserId ? (
        <div>Please select a user</div>
      ) : (
        <>
          <section className="enterprise-servers-section">
            <h2 className="enterprise-section-title">
              Servers for {users.find(u => u.id === selectedUserId)?.telegram_username || 'Selected User'}
            </h2>
            <div className="enterprise-servers-grid">
              {data.servers?.map(server => (
                <div key={server.id} className="enterprise-server-card">
                  <div className="enterprise-server-header">
                    <h3>{server.name}</h3>
                    <div className="enterprise-server-status">
                      <select
                        value={server.status}
                        onChange={(e) => handleServerStatusUpdate(server.id, e.target.value)}
                        className="enterprise-node-select"
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="maintenance">Maintenance</option>
                      </select>
                    </div>
                  </div>
                  <div className="enterprise-server-info">
                    <p>IP: {server.ip}</p>
                    <p>Nodes: {server.nodes?.length || 0}</p>
                  </div>
                  <div className="enterprise-server-actions">
                    <button 
                      onClick={() => handleAddNode(server.id)}
                      className="enterprise-action-btn"
                    >
                      [+] Add Node
                    </button>
                    <button 
                      onClick={() => deleteServer(server.id)}
                      className="enterprise-action-btn enterprise-delete-btn"
                    >
                      [×] Delete Server
                    </button>
                  </div>
                  <div className="enterprise-nodes-list">
                    {(server.nodes || []).map(node => (
                      <div key={node.id} className="enterprise-node-item">
                        <span>{node.type}</span>
                        <select
                          value={node.status}
                          onChange={(e) => handleNodeStatusUpdate(server.id, node.id, e.target.value)}
                          className="enterprise-node-select"
                        >
                          <option value="running">Running</option>
                          <option value="stopped">Stopped</option>
                        </select>
                        <button 
                          onClick={() => deleteNode(server.id, node.id)}
                          className="enterprise-action-btn enterprise-delete-btn"
                        >
                          [×]
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="enterprise-wallets-section">
            <h2 className="enterprise-section-title">Wallets</h2>
            <div className="enterprise-wallets-grid">
              {data.wallets?.map(wallet => (
                <div key={wallet.id} className="enterprise-wallet-card">
                  <h3>{wallet.name}</h3>
                  <div className="enterprise-wallet-info">
                    <p>Type: {wallet.type}</p>
                    <p>Network: {wallet.network}</p>
                    <p className="enterprise-wallet-address">
                      Address: {wallet.address}
                    </p>
                  </div>
                  <div className="enterprise-server-actions">
                    <button 
                      onClick={() => handleLinkNodes(wallet.id)}
                      className="enterprise-action-btn"
                    >
                      [↔] Link Nodes
                    </button>
                    <button 
                      onClick={() => deleteWallet(wallet.id)}
                      className="enterprise-action-btn enterprise-delete-btn"
                    >
                      [×] Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </>
      )}

      {/* Все модальные окна */}
      {showServerModal && (
        <ServerModal
          onClose={() => setShowServerModal(false)}
          onSubmit={handleAddServer}
          userId={selectedUserId}
        />
      )}

      {showWalletModal && (
        <WalletModal
          onClose={() => setShowWalletModal(false)}
          onSubmit={handleAddWallet}
          types={types.wallet_types}
          networks={types.networks}
        />
      )}

      {showNodeModal && (
        <NodeModal
          onClose={() => {
            setShowNodeModal(false);
            setSelectedServer(null);
          }}
          onSubmit={handleNodeSubmit}
          serverId={selectedServer}
          nodeTypes={types.node_types}
          existingNodes={data.servers?.find(s => s.id === selectedServer)?.nodes || []}
        />
      )}

      {showLinkNodesModal && (
        <LinkNodesModal
          onClose={() => {
            setShowLinkNodesModal(false);
            setSelectedWallet(null);
          }}
          onSubmit={handleLinkNodesSubmit}
          walletId={selectedWallet}
          availableNodes={data.servers?.flatMap(s => s.nodes || []) || []}
        />
      )}

      {showAddNodesToAllModal && (
        <NodeModal
          onClose={() => setShowAddNodesToAllModal(false)}
          onSubmit={handleAddNodesToAllServers}
          nodeTypes={types.node_types}
          title="Add Nodes to All Servers"
        />
      )}

      {showBulkStatusModal && (
        <div className="enterprise-modal-overlay">
          <div className="enterprise-modal-content">
            <div className="enterprise-modal-header">
              <h3>Update All Nodes Status</h3>
              <button 
                onClick={() => setShowBulkStatusModal(false)}
                className="enterprise-action-btn enterprise-delete-btn"
              >
                [×]
              </button>
            </div>
            <div className="enterprise-modal-actions">
              <button 
                onClick={() => handleBulkUpdateNodeStatus('start')}
                className="enterprise-action-btn"
              >
                [▶] Start All
              </button>
              <button 
                onClick={() => handleBulkUpdateNodeStatus('stop')}
                className="enterprise-action-btn"
              >
                [⏹] Stop All
              </button>
            </div>
          </div>
        </div>
      )}

      {actionStatus && (
        <div className={`enterprise-notification enterprise-status-${actionStatus}`}>
          {actionStatus === 'loading' && 'Processing...'}
          {actionStatus === 'success' && 'Action completed successfully'}
          {actionStatus === 'error' && 'Action failed'}
        </div>
      )}

      {error && (
        <div className="enterprise-error-notification">
          {error}
        </div>
      )}
    </div>
  );
}

export default AdminEnterprisePage; 