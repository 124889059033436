import React, { useEffect, useRef, useState } from "react";

const AsciiTorus = () => {
  const canvasRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Параметры тора
    const R = 18; // Большой радиус
    const r = 6;  // Малый радиус
    const segments = 60; // Больше сегментов для гладкости
    const rings = 30;   // Больше колец для гладкости
    
    // Размеры экрана в символах
    const screenWidth = 60;
    const screenHeight = 30;
    
    // Размер символа
    const charWidth = 8;
    const charHeight = 14;
    
    canvas.width = screenWidth * charWidth;
    canvas.height = screenHeight * charHeight;
    
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    ctx.font = "12px monospace";
    ctx.textBaseline = "top";

    const buffer = Array(screenHeight).fill().map(() => 
      Array(screenWidth).fill().map(() => ({ char: " ", depth: -Infinity, x: 0, y: 0, z: 0 }))
    );

    // Генерация точек тора
    for (let i = 0; i < segments; i++) {
      const phi = (i * 2 * Math.PI) / segments;
      for (let j = 0; j < rings; j++) {
        const theta = (j * 2 * Math.PI) / rings;
        
        // Параметрические уравнения тора
        const x = (R + r * Math.cos(theta)) * Math.cos(phi);
        const y = (R + r * Math.cos(theta)) * Math.sin(phi);
        const z = r * Math.sin(theta);
        
        // Изометрическая проекция с коррекцией для круглой формы
        const scale = 0.8;
        const isoX = (x - y * 0.5) * scale;
        const isoY = ((x * 0.1 + y * 0.1) - z) * scale;
        
        const screenX = Math.floor(screenWidth/2 + isoX);
        const screenY = Math.floor(screenHeight/2 + isoY);

        // Выбор символов на основе положения и освещения
        const normalX = Math.cos(phi) * Math.cos(theta);
        const normalY = Math.sin(phi) * Math.cos(theta);
        const normalZ = Math.sin(theta);
        
        // Простая модель освещения
        const lightDir = { x: 0.5, y: -0.5, z: 1 };
        const lightIntensity = (normalX * lightDir.x + normalY * lightDir.y + normalZ * lightDir.z) / 
                             Math.sqrt(lightDir.x * lightDir.x + lightDir.y * lightDir.y + lightDir.z * lightDir.z);
        
        let char = " ";
        
        // Символы для каркаса
        if (Math.abs(Math.sin(theta)) > 0.95) {
          char = "─";
        } else if (Math.abs(Math.cos(phi)) > 0.95) {
          char = "│";
        } else if (Math.abs(Math.sin(theta)) > 0.95 && Math.abs(Math.cos(phi)) > 0.95) {
          char = "┼";
        } else {
          // Символы для заполнения между каркасом
          const intensity = (lightIntensity + 1) / 2;
          if (intensity > 0.8) char = "█";
          else if (intensity > 0.6) char = "▓";
          else if (intensity > 0.4) char = "▒";
          else if (intensity > 0.2) char = "░";
          else char = "·";
        }
        
        if (screenX >= 0 && screenX < screenWidth && screenY >= 0 && screenY < screenHeight) {
          const depth = x + y + z;
          if (depth > buffer[screenY][screenX].depth) {
            buffer[screenY][screenX] = { char, depth, x, y, z, intensity: lightIntensity };
          }
        }
      }
    }
    
    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      for (let y = 0; y < screenHeight; y++) {
        for (let x = 0; x < screenWidth; x++) {
          if (buffer[y][x].char !== " ") {
            const cell = buffer[y][x];
            const dx = x * charWidth - mousePos.x;
            const dy = y * charHeight - mousePos.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            
            // Усиленный эффект при ховере
            const hoverIntensity = isHovered ? Math.max(0, 1 - distance / 80) : 0;
            
            // Базовая яркость зависит от глубины и освещения
            const baseAlpha = Math.min(1, 0.5 + cell.depth / 30);
            const alpha = baseAlpha + hoverIntensity * 0.5;
            
            // Увеличенная яркость при ховере
            const baseBrightness = 51 + (cell.intensity + 1) * 50;
            const brightness = Math.min(255, baseBrightness + hoverIntensity * 150);
            
            ctx.fillStyle = `rgba(${51}, ${brightness}, ${51}, ${alpha})`;
            ctx.shadowColor = "#33ff33";
            ctx.shadowBlur = isHovered ? 3 + hoverIntensity * 3 : 2;
            
            // Более заметное увеличение при ховере
            const scale = 1 + hoverIntensity * 0.5;
            ctx.save();
            ctx.translate(x * charWidth + charWidth/2, y * charHeight + charHeight/2);
            ctx.scale(scale, scale);
            ctx.fillText(cell.char, -charWidth/2, -charHeight/2);
            ctx.restore();
          }
        }
      }
    };

    draw();

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      setMousePos({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    };

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseenter', () => setIsHovered(true));
    canvas.addEventListener('mouseleave', () => setIsHovered(false));

    let animationFrame;
    const animate = () => {
      draw();
      animationFrame = requestAnimationFrame(animate);
    };
    
    if (isHovered) {
      animate();
    }

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseenter', () => setIsHovered(true));
      canvas.removeEventListener('mouseleave', () => setIsHovered(false));
      cancelAnimationFrame(animationFrame);
    };
  }, [isHovered, mousePos]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        background: "transparent",
        transform: "rotate(-25deg) scale(0.9)",
        imageRendering: "pixelated",
        cursor: "crosshair",
        transition: "filter 0.3s ease",
        position: "relative",
        zIndex: 10
      }}
    />
  );
};

export default AsciiTorus;
