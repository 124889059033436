import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import '../styles/Infrastructure.css';
import AsciiEarth from '../components/AsciiEarth';
const datacenters = [
  {
    id: 'dc-01',
    location: 'Frankfurt',
    region: 'EU-CENTRAL',
    status: 'OPERATIONAL',
    metrics: {
      nodes: 6482,
      uptime: '99.99%',
      load: '78%',
      network: '1.2 TB/s'
    },
    specs: {
      cpu: '128x AMD EPYC',
      ram: '2 TB DDR5',
      storage: '100 TB NVMe',
      network: '100 Gbps'
    }
  },
  {
    id: 'dc-02',
    location: 'Singapore',
    region: 'ASIA-EAST',
    status: 'OPERATIONAL',
    metrics: {
      nodes: 230,
      uptime: '99.98%',
      load: '65%',
      network: '0.8 TB/s'
    },
    specs: {
      cpu: '64x Intel Xeon',
      ram: '1 TB DDR5',
      storage: '50 TB NVMe',
      network: '40 Gbps'
    }
  },
  {
    id: 'dc-03',
    location: 'Virginia',
    region: 'US-EAST',
    status: 'OPERATIONAL',
    metrics: {
      nodes: 185,
      uptime: '99.99%',
      load: '82%',
      network: '1.5 TB/s'
    },
    specs: {
      cpu: '96x AMD EPYC',
      ram: '1.5 TB DDR5',
      storage: '80 TB NVMe',
      network: '80 Gbps'
    }
  },
  {
    id: 'dc-04',
    location: 'London',
    region: 'EU-WEST',
    status: 'OPERATIONAL',
    metrics: {
      nodes: 156,
      uptime: '99.97%',
      load: '71%',
      network: '1.1 TB/s'
    },
    specs: {
      cpu: '96x Intel Xeon',
      ram: '1.5 TB DDR5',
      storage: '75 TB NVMe',
      network: '60 Gbps'
    }
  },
  {
    id: 'dc-05',
    location: 'Tokyo',
    region: 'ASIA-NORTH',
    status: 'OPERATIONAL',
    metrics: {
      nodes: 198,
      uptime: '99.99%',
      load: '68%',
      network: '1.3 TB/s'
    },
    specs: {
      cpu: '128x AMD EPYC',
      ram: '2 TB DDR5',
      storage: '90 TB NVMe',
      network: '80 Gbps'
    }
  }
];

const blockchains = [
  {
    name: 'NEAR Protocol',
    icon: '◈',
    type: 'Validator',
    status: 'Active',
    nodes: 1,
    uptime: '99.98%'
  },
  {
    name: 'Solana',
    icon: '◎',
    type: 'Validator',
    status: 'Active',
    nodes: 1,
    uptime: '99.99%'
  },
  {
    name: 'OpenLedger',
    icon: '⬡',
    type: 'Testnet',
    status: 'Active',
    nodes: 6482,
    uptime: '99.97%'
  },
  {
    name: 'Waku',
    icon: '⬢',
    type: 'Testnet',
    status: 'Active',
    nodes: 230,
    uptime: '99.95%'
  },
  {
    name: 'Hemi',
    icon: '◇',
    type: 'Testnet',
    status: 'Active',
    nodes: 538,
    uptime: '99.98%'
  }
];

const systemLogs = [
  { id: 1, type: 'info', timestamp: '2024-03-10 12:00:15', message: 'System health check completed' },
  { id: 2, type: 'success', timestamp: '2024-03-10 12:00:30', message: 'New node deployed in Frankfurt DC' },
  { id: 3, type: 'warning', timestamp: '2024-03-10 12:01:45', message: 'High load detected on US-EAST-01' },
  { id: 4, type: 'error', timestamp: '2024-03-10 12:02:10', message: 'Connection timeout on ASIA-NODE-05' },
  { id: 5, type: 'info', timestamp: '2024-03-10 12:03:00', message: 'Automatic scaling initiated' }
];

const updates = [
  {
    id: 1,
    date: '2024-03-10',
    type: 'security',
    title: 'Security Protocol Update',
    description: 'Enhanced firewall rules and access controls implemented'
  },
  {
    id: 2,
    date: '2024-03-09',
    type: 'performance',
    title: 'Network Optimization',
    description: 'Improved inter-DC connection speed by 25%'
  },
  {
    id: 3,
    date: '2024-03-08',
    type: 'maintenance',
    title: 'System Maintenance',
    description: 'Regular system updates and optimizations completed'
  }
];

function Infrastructure() {
  const [selectedDC, setSelectedDC] = useState(null);
  const [systemStatus, setSystemStatus] = useState({
    cpu: 75,
    memory: 68,
    storage: 45,
    network: 82
  });
  const [logs, setLogs] = useState(systemLogs);
  const [activeConnections, setActiveConnections] = useState([]);
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  const [globePoints, setGlobePoints] = useState([
    { x: 30, y: 30, active: true },  // Frankfurt
    { x: 70, y: 40, active: true },  // Singapore
    { x: 20, y: 35, active: true },  // Virginia
    { x: 25, y: 28, active: true },  // London
    { x: 80, y: 35, active: true }   // Tokyo
  ]);

  useEffect(() => {
    const statusInterval = setInterval(() => {
      setSystemStatus(prev => ({
        cpu: Math.floor(Math.random() * 20) + 65,
        memory: Math.floor(Math.random() * 15) + 60,
        storage: Math.floor(Math.random() * 10) + 40,
        network: Math.floor(Math.random() * 25) + 70
      }));
    }, 3000);

    const logsInterval = setInterval(() => {
      const newLog = {
        id: Date.now(),
        type: ['info', 'success', 'warning', 'error'][Math.floor(Math.random() * 4)],
        timestamp: new Date().toLocaleString(),
        message: `System event ${Math.floor(Math.random() * 1000)}`
      };
      setLogs(prev => [newLog, ...prev].slice(0, 10));
    }, 5000);

    const connectionsInterval = setInterval(() => {
      const connections = datacenters.map(dc => ({
        from: dc.id,
        to: datacenters[Math.floor(Math.random() * datacenters.length)].id,
        strength: Math.random(),
        status: Math.random() > 0.1 ? 'ACTIVE' : 'HIGH_LOAD',
        speed: `${(Math.random() * 10).toFixed(1)} Gb/s`,
        type: Math.random() > 0.5 ? 'SECURE_TUNNEL' : 'DIRECT_LINK',
        latency: `${Math.floor(Math.random() * 50)}ms`
      }));
      setActiveConnections(connections);
    }, 2000);

    const globeInterval = setInterval(() => {
      setGlobePoints(prev => prev.map(point => ({
        ...point,
        active: Math.random() > 0.3
      })));
    }, 2000);

    return () => {
      clearInterval(statusInterval);
      clearInterval(logsInterval);
      clearInterval(connectionsInterval);
      clearInterval(globeInterval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Infrastructure Overview - xNod3</title>
        <meta name="description" content="xNod3 global infrastructure overview, real-time metrics and system status" />
      </Helmet>

      
      <div className="xinfra-container">
        <div className="xinfra-header">
          <div className="xinfra-title">
            <span className="xinfra-prompt">&gt;</span>
            <span className="xinfra-command">infrastructure.status</span>
            <span className="xinfra-cursor">_</span>
          </div>
          
          <div className="xinfra-system-status">
            {Object.entries(systemStatus).map(([key, value]) => (
              <div key={key} className="xinfra-status-metric">
                <div className="xinfra-metric-bar">
                  <div className="xinfra-metric-fill" style={{ width: `${value}%` }}></div>
                </div>
                <span className="xinfra-metric-label">{key.toUpperCase()}: {value}%</span>
              </div>
            ))}
          </div>
        </div>

        <div className="xinfra-grid">
          <section className="xinfra-globe">
            <h2 className="section-title">[GLOBAL_NETWORK]</h2>
            <div className="globe-container">
              <div className="globe-canvas-container">
                <AsciiEarth dataPoints={globePoints} />
              </div>
            </div>
            <div className="globe-legend">
              <div className="legend-item">
                <span className="legend-dot"></span>
                <span className="legend-text">Frankfurt [EU-CENTRAL]</span>
              </div>
              <div className="legend-item">
                <span className="legend-dot"></span>
                <span className="legend-text">Singapore [ASIA-EAST]</span>
              </div>
              <div className="legend-item">
                <span className="legend-dot"></span>
                <span className="legend-text">Virginia [US-EAST]</span>
              </div>
              <div className="legend-item">
                <span className="legend-dot"></span>
                <span className="legend-text">London [EU-WEST]</span>
              </div>
              <div className="legend-item">
                <span className="legend-dot"></span>
                <span className="legend-text">Tokyo [ASIA-NORTH]</span>
              </div>
            </div>
          </section>

          <section className="xinfra-map">
            <h2 className="xinfra-section-title">[GLOBAL_INFRASTRUCTURE]</h2>
            <div className="xinfra-datacenter-grid">
              {datacenters.map(dc => (
                <div 
                  key={dc.id}
                  className={`xinfra-datacenter-card ${selectedDC === dc.id ? 'active' : ''}`}
                  onClick={() => setSelectedDC(dc.id)}
                >
                  <div className="xinfra-dc-header">
                    <span className="xinfra-dc-status-dot"></span>
                    <span className="xinfra-dc-location">{dc.location}</span>
                    <span className="xinfra-dc-region">{dc.region}</span>
                  </div>
                  
                  <div className="xinfra-dc-metrics">
                    <div className="xinfra-dc-metric">
                      <span className="xinfra-metric-icon">⬢</span>
                      <span className="xinfra-metric-value">{dc.metrics.nodes}</span>
                      <span className="xinfra-metric-label">NODES</span>
                    </div>
                    <div className="xinfra-dc-metric">
                      <span className="xinfra-metric-icon">↟</span>
                      <span className="xinfra-metric-value">{dc.metrics.uptime}</span>
                      <span className="xinfra-metric-label">UPTIME</span>
                    </div>
                    <div className="xinfra-dc-metric">
                      <span className="xinfra-metric-icon">⚡</span>
                      <span className="xinfra-metric-value">{dc.metrics.load}</span>
                      <span className="xinfra-metric-label">LOAD</span>
                    </div>
                  </div>

                  {selectedDC === dc.id && (
                    <div className="xinfra-dc-details">
                      <div className="xinfra-dc-specs">
                        <h3 className="xinfra-specs-title">⚙ SPECIFICATIONS</h3>
                        {Object.entries(dc.specs).map(([key, value]) => (
                          <div key={key} className="xinfra-spec-item">
                            <span className="xinfra-spec-key">
                              {key === 'cpu' && '⚛ '}
                              {key === 'ram' && '▦ '}
                              {key === 'storage' && '◫ '}
                              {key === 'network' && '⇄ '}
                              {key.toUpperCase()}
                            </span>
                            <span className="xinfra-spec-value">{value}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>

          <section className="xinfra-blockchains">
            <h2 className="xinfra-section-title">[ACTIVE_NETWORKS]</h2>
            <div className="xinfra-blockchain-grid">
              {blockchains.map(chain => (
                <div key={chain.name} className="xinfra-blockchain-card">
                  <div className="xinfra-chain-header">
                    <span className="xinfra-chain-icon">{chain.icon}</span>
                    <span className="xinfra-chain-name">{chain.name}</span>
                    <span className={`xinfra-chain-status ${chain.status.toLowerCase()}`}>
                      [{chain.status}]
                    </span>
                  </div>
                  
                  <div className="xinfra-chain-metrics">
                    <div className="xinfra-chain-metric">
                      <span className="xinfra-metric-label">TYPE</span>
                      <span className="xinfra-metric-value">{chain.type}</span>
                    </div>
                    <div className="xinfra-chain-metric">
                      <span className="xinfra-metric-label">NODES</span>
                      <span className="xinfra-metric-value">{chain.nodes}</span>
                    </div>
                    <div className="xinfra-chain-metric">
                      <span className="xinfra-metric-label">UPTIME</span>
                      <span className="xinfra-metric-value">{chain.uptime}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="xinfra-terminal">
            <h2 className="xinfra-section-title">[SYSTEM_LOGS]</h2>
            <div className="xinfra-terminal-window">
              <div className="xinfra-terminal-header">
                <span className="xinfra-terminal-dot"></span>
                <span className="xinfra-terminal-dot"></span>
                <span className="xinfra-terminal-dot"></span>
                <span className="xinfra-terminal-title">system.log</span>
              </div>
              <div className="xinfra-terminal-content">
                {logs.map(log => (
                  <div key={log.id} className={`xinfra-log-entry ${log.type}`}>
                    <span className="xinfra-log-timestamp">[{log.timestamp}]</span>
                    <span className={`xinfra-log-type ${log.type}`}>[{log.type.toUpperCase()}]</span>
                    <span className="xinfra-log-message">{log.message}</span>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="xinfra-updates">
            <h2 className="section-title">[SYSTEM_UPDATES]</h2>
            <div className="updates-grid">
              {updates.map(update => (
                <div 
                  key={update.id} 
                  className={`update-card ${update.type} ${selectedUpdate === update.id ? 'active' : ''}`}
                  onClick={() => setSelectedUpdate(update.id === selectedUpdate ? null : update.id)}
                >
                  <div className="update-header">
                    <span className="update-type">[{update.type}]</span>
                    <span className="update-date">{update.date}</span>
                  </div>
                  <h3 className="update-title">{update.title}</h3>
                  {selectedUpdate === update.id && (
                    <p className="update-description">{update.description}</p>
                  )}
                </div>
              ))}
            </div>
          </section>

          <section className="xinfra-network">
            <h2 className="xinfra-section-title">[NETWORK_ACTIVITY]</h2>
            <div className="xinfra-network-map">
              {activeConnections.map((connection, index) => (
                <div 
                  key={index}
                  className="xinfra-network-connection"
                  style={{
                    opacity: 0.9
                  }}
                >
                  <span className="xinfra-connection-start">⟡ {connection.from}</span>
                  <div className="xinfra-connection-details">
                    <div className="xinfra-connection-status">
                      <span className={`xinfra-status-indicator ${connection.status.toLowerCase()}`}></span>
                      {connection.status}
                    </div>
                    <div className="xinfra-connection-info">
                      <span className="xinfra-connection-type">{connection.type}</span>
                      <span className="xinfra-connection-speed">{connection.speed}</span>
                      <span className="xinfra-connection-latency">{connection.latency}</span>
                    </div>
                  </div>
                  <span className="xinfra-connection-line">─</span>
                  <span className="xinfra-connection-end">⟡ {connection.to}</span>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Infrastructure;