import React, { useState, useEffect } from 'react';
import '../styles/EnterpriseTab.css';
import api from '../services/api';

// Вспомогательные функции
const convertToYAML = (data) => {
  const yaml = data.map(item => {
    const entries = Object.entries(item)
      .map(([key, value]) => `  ${key}: ${JSON.stringify(value)}`)
      .join('\n');
    return `- ${entries}`;
  }).join('\n');
  return yaml;
};

const downloadFile = (content, fileName, contentType) => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const convertToCSV = (data) => {
  if (!data.length) return '';
  const headers = Object.keys(data[0]);
  const csvRows = [headers.join(',')];
  for (const row of data) {
    const values = headers.map(header => {
      const val = row[header];
      return typeof val === 'string' ? `"${val}"` : val;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

// Компоненты иконок
const StatusIcon = ({ status }) => {
  const icons = {
    active: '🟢',
    maintenance: '🟡',
    stopped: '🔴',
    installing: '🔄'
  };
  
  return (
    <div className={`status-icon ${status}`} title={status}>
      {icons[status] || '❓'}
    </div>
  );
};

const NodeIcon = ({ type, status }) => {
  const getNodeInfo = () => {
    switch (type) {
      case 'Ritual':
        return { color: 'neon-purple', network: 'Base' };
      case 'Hemi':
        return { color: 'neon-orange', network: 'tBTC' };
      case 'Hyperlane':
        return { color: 'neon-blue', network: 'Base' };
      default:
        return { color: '', network: '' };
    }
  };

  const { color, network } = getNodeInfo();

  return (
    <span className={`node-icon ${color} ${status}`} title={`${type} (${network}) - ${status}`}>
      [{type}]
    </span>
  );
};

const WalletTypeIcon = ({ type }) => {
  const getTypeStyle = () => {
    switch (type) {
      case 'ETH':
        return 'neon-blue';
      case 'BTC':
        return 'neon-orange';
      default:
        return '';
    }
  };

  return (
    <div className={`wallet-type-icon ${getTypeStyle()}`}>
      [{type}]
    </div>
  );
};

// Компонент для уведомлений
const ActionNotification = ({ message, type }) => (
  <div className={`action-notification ${type}`}>
    [{message}]
  </div>
);

// Компонент для отображения связанных нод и серверов
const LinkedNodesInfo = ({ linkedNodes, enterpriseData }) => {
  // Добавляем проверку и преобразование
  const nodeIds = Array.isArray(linkedNodes) ? linkedNodes : [];
  
  // Группируем ноды по серверам
  const groupedByServer = nodeIds.reduce((acc, nodeId) => {
    const serverInfo = enterpriseData.servers.find(server => 
      server.nodes.some(node => node.id === nodeId)
    );
    
    if (serverInfo) {
      const nodeInfo = serverInfo.nodes.find(node => node.id === nodeId);
      if (!acc[serverInfo.id]) {
        acc[serverInfo.id] = {
          server: serverInfo,
          nodes: []
        };
      }
      if (nodeInfo) {
        acc[serverInfo.id].nodes.push(nodeInfo);
      }
    }
    return acc;
  }, {});

  return (
    <div className="linked-nodes-container">
      {Object.values(groupedByServer).map(({ server, nodes }) => (
        <div key={server.id} className="server-nodes-group">
          <div className="server-info">
            <StatusIcon status={server.status} />
            <span className="server-name mono-text" title={server.ip}>
              {server.name}
            </span>
          </div>
          <div className="nodes-list">
            {nodes.map(node => (
              <NodeIcon 
                key={node.id} 
                type={node.type} 
                status={node.status}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

// Компонент модального окна
const DetailsModal = ({ data, onClose, copyToClipboard }) => {
  const [showPrivate, setShowPrivate] = useState({
    password: false,
    private_key: false
  });

  if (!data) return null;

  const togglePrivateField = (field) => {
    setShowPrivate(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const renderValue = (key, value) => {
    // Для приватных полей (пароль сервера и приватный ключ кошелька)
    if (key === 'password' || key === 'private_key') {
      return (
        <div className="private-data-container">
          <div className="private-data">
            <span className="mono-text">
              {showPrivate[key] ? value : '••••••••••••••••'}
            </span>
          </div>
          <button 
            className={`view-button ${showPrivate[key] ? 'active' : ''}`}
            onClick={() => togglePrivateField(key)}
            title={showPrivate[key] ? 'Hide value' : 'Show value'}
          >
            [{showPrivate[key] ? '👁' : '👁‍🗨'}]
          </button>
          {showPrivate[key] && (
            <button 
              className="copy-button" 
              onClick={() => copyToClipboard(value)}
              title={`Copy ${key}`}
            >
              [⎘]
            </button>
          )}
        </div>
      );
    }

    // Для серверов
    if (key === 'nodes') {
      return (
        <div className="nodes-grid">
          {value.map(node => (
            <div key={node.id} className="node-detail-card">
              <div className="node-info">
                <NodeIcon type={node.type} status={node.status} />
                <span className="node-name">
                  Node {node.id}
                </span>
                <span className={`node-status ${node.status}`}>
                  {node.status === 'running' ? '[⚡ ONLINE]' : '[✖ OFFLINE]'}
                </span>
              </div>
            </div>
          ))}
        </div>
      );
    }

    // Для IP и пароля сервера
    if (key === 'ip' || key === 'password') {
      return (
        <div className={`${key}-container`}>
          <span className="mono-text">{value}</span>
          <button 
            className="copy-button" 
            onClick={() => copyToClipboard(value)}
            title={`Copy ${key}`}
          >
            [⎘]
          </button>
        </div>
      );
    }

    // Для linkedNodesDetails (кошельки)
    if (key === 'linkedNodesDetails') {
      return (
        <div className="nodes-grid">
          {value.map(node => (
            <div key={node.id} className="node-detail-card">
              <div className="server-info">
                <StatusIcon status={node.serverStatus} />
                <span className="server-name">[{node.serverName}]</span>
                <span className={`server-status ${node.serverStatus}`}>
                  {node.serverStatus === 'active' ? '⚡ ONLINE' : '✖ OFFLINE'}
                </span>
              </div>
              <div className="node-info">
                <NodeIcon type={node.type} status={node.status} />
                <span className="node-name">
                  Node {node.id}
                </span>
                <span className={`node-status ${node.status}`}>
                  {node.status === 'running' ? '[⚡ ONLINE]' : '[✖ OFFLINE]'}
                </span>
              </div>
            </div>
          ))}
        </div>
      );
    }

    // Для обычных полей
    if (typeof value === 'string' || typeof value === 'number') {
      return <span className="detail-value mono-text">{value}</span>;
    }

    return null;
  };

  // Определяем, является ли это сервером
  const isServer = 'nodes' in data;
  
  // Определяем порядок полей в зависимости от типа
  const fieldOrder = isServer 
    ? ['status', 'ip', 'password', 'nodes']
    : ['type', 'address', 'network', 'private_key', 'linkedNodesDetails'];

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content cyber-panel" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <div className="header-info">
            <h3 className="neon-text">{data.name}</h3>
            {isServer ? <StatusIcon status={data.status} /> : <WalletTypeIcon type={data.type} />}
          </div>
          <button className="close-button" onClick={onClose}>[×]</button>
        </div>
        <div className="modal-body">
          {fieldOrder.map(key => {
            if (!data[key]) return null;
            return (
              <div key={key} className="detail-row">
                <span className="detail-label">{key}:</span>
                {renderValue(key, data[key])}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Функция для поиска и фильтрации элементов
const filterItems = (items = [], query = '', type) => {
  if (!query || !items) return items || [];
  
  return items.filter(item => {
    const searchFields = type === 'servers' 
      ? ['name', 'ip'] // поля для поиска по серверам
      : ['name', 'address', 'type']; // поля для поиска по кошелькам
      
    return searchFields.some(field => 
      item[field]?.toString().toLowerCase().includes(query.toLowerCase())
    );
  });
};

// Основной компонент
const EnterpriseTab = () => {
  const [exportFormat, setExportFormat] = useState('json');
  const [currentPage, setCurrentPage] = useState({ servers: 1, wallets: 1 });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState({ servers: '', wallets: '' });
  const [sortConfig, setSortConfig] = useState({ 
    servers: { field: 'name', direction: 'asc' },
    wallets: { field: 'name', direction: 'asc' }
  });
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [copiedAddress, setCopiedAddress] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [enterpriseData, setEnterpriseData] = useState({ servers: [], wallets: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const fetchEnterpriseData = async () => {
      try {
        const response = await api.get('/enterprise');
        setEnterpriseData(response.data);
        setError(null);
        // Запускаем анимацию исчезновения только при первой загрузке
        if (loading) {
          setFadeOut(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (err) {
        console.error('Failed to fetch enterprise data:', err);
        if (err.response?.status === 403) {
          setError('Access denied. Enterprise subscription required.');
        } else {
          setError('Failed to load enterprise data. Please try again later.');
        }
        setLoading(false);
      }
    };

    // Первоначальная загрузка
    fetchEnterpriseData();

    // Сохраняем интервал обновления
    const interval = setInterval(fetchEnterpriseData, 30000);

    // Очистка интервала при размонтировании
    return () => clearInterval(interval);
  }, [loading]); // Добавляем loading в зависимости

  // Заменяем все упоминания ENTERPRISE_DATA на enterpriseData
  const filteredServers = filterItems(enterpriseData.servers, searchQuery.servers, 'servers');
  const filteredWallets = filterItems(enterpriseData.wallets, searchQuery.wallets, 'wallets');

  // Функции для работы с данными
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Можно добавить уведомление  копировании
      console.log('Copied:', text);
    });
  };

  const findNodeById = (nodeId) => {
    for (const server of enterpriseData.servers) {
      const node = server.nodes.find(n => n.id === nodeId);
      if (node) return node;
    }
    return null;
  };

  const handleShowWalletDetails = (walletId) => {
    const wallet = enterpriseData.wallets.find(w => w.id === walletId);
    if (wallet) {
      setSelectedDetails({
        ...wallet,
        linkedNodes: wallet.linkedNodes.map(nodeId => findNodeById(nodeId)).filter(Boolean)
      });
      setShowDetailsModal(true);
    }
  };

  const handleExport = async (type, id = null) => {
    let content = '';
    const timestamp = new Date().toISOString().slice(0, 19).replace(/[:]/g, '-');
    
    if (id) {
      const item = type === 'servers' 
        ? enterpriseData.servers.find(s => s.id === id)
        : enterpriseData.wallets.find(w => w.id === id);
      
      if (item) {
        content = JSON.stringify(item, null, 2);
        downloadFile(content, `${type}_${item.name}_${timestamp}.json`, 'application/json');
      }
      return;
    }

    if (type === 'private') {
      content = enterpriseData.wallets.map(w => 
        `${w.address}:${w.private_key || 'KEY_ENCRYPTED'}`
      ).join('\n');
      downloadFile(content, `private_keys_${timestamp}.txt`, 'text/plain');
      return;
    }

    const data = type === 'servers' ? enterpriseData.servers : enterpriseData.wallets;
    
    switch (exportFormat) {
      case 'json':
        content = JSON.stringify(data, null, 2);
        downloadFile(content, `${type}_${timestamp}.json`, 'application/json');
        break;
      case 'csv':
        content = convertToCSV(data);
        downloadFile(content, `${type}_${timestamp}.csv`, 'text/csv');
        break;
      case 'yaml':
        content = convertToYAML(data);
        downloadFile(content, `${type}_${timestamp}.yaml`, 'text/yaml');
        break;
    }
  };

  // Функция для пагинации
  const paginate = (items, page) => {
    const start = (page - 1) * itemsPerPage;
    return items.slice(start, start + itemsPerPage);
  };

  // Функция для сортировки
  const sortItems = (items, type) => {
    const { field, direction } = sortConfig[type];
    return [...items].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Функция для обработки сортировки
  const handleSort = (type, field) => {
    setSortConfig(prev => ({
      ...prev,
      [type]: {
        field,
        direction: prev[type].field === field && prev[type].direction === 'asc' ? 'desc' : 'asc'
      }
    }));
  };

  // Функция для показа деталей сервера
  const handleShowServerDetails = (serverId) => {
    const server = enterpriseData.servers.find(s => s.id === serverId);
    if (server) {
      setSelectedDetails(server);
      setShowDetailsModal(true);
    }
  };

  // Обновляем компонент таблицы серверов
  const ServerRow = ({ server, onShowDetails }) => {
    const [notification, setNotification] = useState(null);

    const showNotification = (message, type) => {
      setNotification({ message, type });
      setTimeout(() => setNotification(null), 2000);
    };

    return (
      <div className="table-row cyber-row">
        <div className="cell">
          <StatusIcon status={server.status} />
        </div>
        <div className="cell mono-text">{server.name}</div>
        <div className="cell mono-text">
          <span className="ip-address">{server.ip}</span>
          <button className="copy-button" onClick={() => copyToClipboard(server.ip)}>
            [⎘]
          </button>
        </div>
        <div className="cell">
          <div className="nodes-container">
            {server.nodes.map(node => (
              <NodeIcon key={node.id} type={node.type} status={node.status} />
            ))}
          </div>
        </div>
        <div className="cell actions">
          <button 
            className="action-button view" 
            onClick={() => onShowDetails(server.id)}
            title="View server details"
          >
            [👁]
          </button>
          <button 
            className="action-button password" 
            onClick={() => {
              copyToClipboard(server.password);
              showNotification('Password copied', 'success');
            }}
            title="Copy server password"
          >
            [🔑]
          </button>
          {notification && <ActionNotification {...notification} />}
        </div>
      </div>
    );
  };

  const WalletRow = ({ wallet, onShowDetails, onExport, enterpriseData }) => {
    const [notification, setNotification] = useState(null);

    const showNotification = (message, type) => {
      setNotification({ message, type });
      setTimeout(() => setNotification(null), 2000);
    };

    return (
      <div className="table-row cyber-row">
        <div className="cell">
          <WalletTypeIcon type={wallet.type} />
        </div>
        <div className="cell mono-text">{wallet.name}</div>
        <div className="cell address-cell">
          <span className="address mono-text">{wallet.address}</span>
          <button 
            className="copy-button" 
            onClick={() => copyToClipboard(wallet.address)}
            title="Copy address"
          >
            [⎘]
          </button>
        </div>
        <div className="cell linked-nodes-cell">
          <LinkedNodesInfo 
            linkedNodes={wallet.linkedNodes} 
            enterpriseData={enterpriseData}
          />
        </div>
        <div className="cell actions">
          <button 
            className="action-button view" 
            onClick={() => {
              onShowDetails(wallet.id);
              showNotification('Opening details...', 'info');
            }}
            title="View wallet details"
          >
            [👁]
          </button>
          <button 
            className="action-button export" 
            onClick={() => {
              onExport(wallet.id);
              showNotification('Exporting wallet...', 'success');
            }}
            title="Export wallet data"
          >
            [↓]
          </button>
          <button 
            className="action-button private-key"
            onClick={() => {
              copyToClipboard(wallet.private_key);
              showNotification('Private key copied', 'success');
            }}
            title="Copy private key"
          >
            [🔑]
          </button>
          {notification && <ActionNotification {...notification} />}
        </div>
      </div>
    );
  };

  // Добавляем стили для кнопок и тултипа
  const styles = `
    .actions {
      display: flex;
      gap: 0.5rem;
      position: relative;
    }

    .action-button {
      background: transparent;
      border: 1px solid rgba(0, 255, 255, 0.2);
      color: #0ff;
      padding: 0.4rem;
      cursor: pointer;
      transition: all 0.3s ease;
      font-family: 'Anonymous Pro', monospace;
    }

    .action-button:hover {
      border-color: rgba(0, 255, 255, 0.5);
      background: rgba(0, 255, 255, 0.1);
      transform: translateY(-1px);
    }

    .action-button.view {
      color: #0ff;
    }

    .action-button.export {
      color: #0f0;
    }

    .action-button.private-key {
      color: #f0f;
    }

    .tooltip {
      position: absolute;
      bottom: 100%;
      right: 0;
      background: rgba(0, 255, 255, 0.1);
      border: 1px solid rgba(0, 255, 255, 0.2);
      padding: 0.5rem;
      border-radius: 3px;
      color: #0ff;
      font-size: 0.8rem;
      white-space: nowrap;
      animation: fadeIn 0.3s ease;
    }

    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(5px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  // Добавляем стили в документ
  const styleSheet = document.createElement("style");
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  // Обновляем обработчики
  const handleShowDetails = (id, type) => {
    if (type === 'wallet') {
      const wallet = enterpriseData.wallets.find(w => w.id === id);
      if (wallet) {
        const linkedNodesDetails = (wallet.linkedNodes || []).map(nodeId => {
          const server = enterpriseData.servers.find(s => 
            s.nodes.some(n => n.id === nodeId)
          );
          const node = server?.nodes.find(n => n.id === nodeId);
          if (server && node) {
            return {
              ...node,
              serverName: server.name,
              serverStatus: server.status
            };
          }
          return null;
        }).filter(Boolean);

        setModalData({
          ...wallet,
          linkedNodesDetails
        });
      }
    } else {
      const server = enterpriseData.servers.find(s => s.id === id);
      if (server) {
        setModalData({
          ...server,
          nodesDetails: server.nodes.map(node => ({
            ...node,
            linkedWallets: enterpriseData.wallets
              .filter(w => w.linkedNodes && w.linkedNodes.includes(node.id))
              .map(w => w.name)
          }))
        });
      }
    }
    setShowDetailsModal(true);
  };

  // Добавляем отображение загрузки и ошибок
  if (loading) {
    return (
      <div className={`enterprise-dashboard loading ${fadeOut ? 'fade-out' : ''}`}>
        <div className="cyber-panel loading-panel">
          <div className="loading-content">
            <div className="loading-header">
              <span className="loading-icon">[↻]</span>
              <h3 className="loading-text glitch">System Initialization</h3>
              <span className="loading-icon">[↻]</span>
            </div>

            <div className="loading-progress">
              <div className="progress-line"></div>
              <div className="progress-text">
                <span className="matrix-char">[</span>
                <span className="status-text">Loading Enterprise Data</span>
                <span className="matrix-char">]</span>
              </div>
            </div>

            <div className="loading-details">
              <div className="detail-line">
                <span className="detail-icon">[>]</span>
                <span className="detail-text">Connecting to Blockchain Network</span>
              </div>
              <div className="detail-line">
                <span className="detail-icon">[*]</span>
                <span className="detail-text">Verifying Node Status</span>
              </div>
              <div className="detail-line">
                <span className="detail-icon">[%]</span>
                <span className="detail-text">Syncing Infrastructure Data</span>
              </div>
            </div>

            <div className="matrix-line">
              <span className="matrix-char">[</span>
              <span className="matrix-text">Status: Initializing</span>
              <span className="matrix-char">]</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="enterprise-dashboard error">
        <div className="cyber-panel error-panel access-denied">
          <div className="error-header">
            <span className="error-icon">[!]</span>
            <h3 className="neon-text glitch">Access Denied</h3>
            <span className="error-icon">[!]</span>
          </div>

          <div className="error-content">
            <div className="matrix-line">
              <span className="matrix-char">[</span>
              <span className="matrix-text">System Status: Restricted</span>
              <span className="matrix-char">]</span>
            </div>
            
            <p className="access-error-message neon-red">
              {error}
            </p>

            <div className="error-details">
              <div className="detail-line">
                <span className="detail-icon">[>]</span>
                <span className="detail-text">Node Deployment</span>
              </div>
              <div className="detail-line">
                <span className="detail-icon">[*]</span>
                <span className="detail-text">24/7 Monitoring & Support</span>
              </div>
              <div className="detail-line">
                <span className="detail-icon">[%]</span>
                <span className="detail-text">Profit Optimization & ROI Tracking</span>
              </div>
            </div>

            <div className="matrix-line">
              <span className="matrix-char">[</span>
              <span className="matrix-text">Uptime: 99.99% | ROI: +2000%</span>
              <span className="matrix-char">]</span>
            </div>

            <button 
              className="cyber-button enterprise-access" 
              onClick={() => window.location.href = '/contact'}
            >
              <span className="button-icon">[↗]</span>
              <span className="button-text">Get Enterprise Access</span>
              <span className="button-cursor">_</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="enterprise-dashboard">
      <div className="enterprise-header">
        <div className="header-title">
          <span className="header-icon">[⚡]</span>
          <h2 className="neon-text">Enterprise Infrastructure<span className="cursor">_</span></h2>
        </div>
        <div className="header-actions">
          <div className="export-group">
            <select className="cyber-select" value={exportFormat} onChange={(e) => setExportFormat(e.target.value)}>
              <option value="json">JSON</option>
              <option value="csv">CSV</option>
              <option value="yaml">YAML</option>
            </select>
            <button className="cyber-button neon-text" onClick={() => handleExport('servers')}>
              [↓] EXPORT_SERVERS
            </button>
            <button className="cyber-button neon-text" onClick={() => handleExport('wallets')}>
              [↓] EXPORT_WALLETS
            </button>
            <button className="cyber-button warning neon-red" onClick={() => handleExport('private')}>
              [!] EXPORT_PRIVATE_KEYS
            </button>
          </div>
        </div>
      </div>

      {/* Серверы */}
      <div className="grid-section servers cyber-panel">
        <div className="section-header">
          <div className="section-title">
            <h3 className="neon-text">[>] Infrastructure Overview</h3>
            <div className="section-stats">
              <span className="stat">Active: {enterpriseData.servers.filter(s => s.status === 'active').length}</span>
              <span className="stat">Total: {enterpriseData.servers.length}</span>
            </div>
          </div>
          <div className="section-controls">
            <input
              type="text"
              className="cyber-search"
              placeholder="Search servers..."
              value={searchQuery.servers}
              onChange={(e) => setSearchQuery(prev => ({ ...prev, servers: e.target.value }))}
            />
            <select 
              className="cyber-select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value="10">10 / page</option>
              <option value="25">25 / page</option>
              <option value="50">50 / page</option>
            </select>
          </div>
        </div>

        <div className="data-table">
          <div className="table-header cyber-bg">
            <div className="header-cell" onClick={() => handleSort('servers', 'status')}>
              Status [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('servers', 'name')}>
              Name [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('servers', 'ip')}>
              IP Address [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('servers', 'nodes')}>
              Nodes [↕]
            </div>
            <div className="header-cell">Actions</div>
          </div>

          <div className="table-body">
            {filteredServers.map(server => (
              <ServerRow 
                key={server.id}
                server={server}
                onShowDetails={() => handleShowDetails(server.id, 'server')}
              />
            ))}
          </div>

          <Pagination
            currentPage={currentPage.servers}
            totalItems={filteredServers.length}
            itemsPerPage={itemsPerPage}
            onPageChange={(page) => setCurrentPage(prev => ({ ...prev, servers: page }))}
          />
        </div>
      </div>

      {/* Кошельки */}
      <div className="grid-section wallets cyber-panel">
        <div className="section-header">
          <div className="section-title">
            <h3 className="neon-text">
              <span className="header-icon">[⛓]</span> 
              Wallet Management
            </h3>
            <div className="section-stats">
              <span className="stat">
                <span className="stat-icon">[ETH]</span> 
                {enterpriseData.wallets.filter(w => w.type === 'ETH').length}
              </span>
              <span className="stat">Total: {enterpriseData.wallets.length}</span>
            </div>
          </div>
          <div className="section-controls">
            <div className="search-box">
              <span className="search-icon">[🔍]</span>
              <input
                type="text"
                className="cyber-search"
                placeholder="Search wallets..."
                value={searchQuery.wallets}
                onChange={(e) => setSearchQuery(prev => ({ ...prev, wallets: e.target.value }))}
              />
            </div>
            <select 
              className="cyber-select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value="10">10 / page</option>
              <option value="25">25 / page</option>
              <option value="50">50 / page</option>
            </select>
          </div>
        </div>

        <div className="data-table wallet-table">
          <div className="table-header cyber-bg">
            <div className="header-cell" onClick={() => handleSort('wallets', 'type')}>
              Type [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('wallets', 'name')}>
              Name [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('wallets', 'address')}>
              Address [↕]
            </div>
            <div className="header-cell" onClick={() => handleSort('wallets', 'linkedNodes')}>
              Linked Nodes [↕]
            </div>
            <div className="header-cell">Actions</div>
          </div>

          <div className="table-body">
            {filteredWallets.map(wallet => (
              <WalletRow 
                key={wallet.id}
                wallet={wallet}
                onShowDetails={() => handleShowDetails(wallet.id, 'wallet')}
                onExport={() => handleExport('wallets', wallet.id)}
                enterpriseData={enterpriseData}
              />
            ))}
          </div>

          <Pagination
            currentPage={currentPage.wallets}
            totalItems={filteredWallets.length}
            itemsPerPage={itemsPerPage}
            onPageChange={(page) => setCurrentPage(prev => ({ ...prev, wallets: page }))}
          />
        </div>
      </div>

      {/* Добавляем модальное окно */}
      {modalData && (
        <DetailsModal 
          data={modalData} 
          onClose={() => setModalData(null)}
          copyToClipboard={copyToClipboard}
        />
      )}
    </div>
  );
};

// Компонент пагинации
const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  return (
    <div className="pagination cyber-pagination">
      <button 
        className="cyber-button" 
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        [←]
      </button>
      <span className="page-info neon-text">
        {currentPage} / {totalPages}
      </span>
      <button 
        className="cyber-button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        [→]
      </button>
    </div>
  );
};

export default EnterpriseTab;