import React, { useState } from 'react';
import '../../styles/modals/Modal.css';

// Списки слов для генерации имен кошельков
const WALLET_PREFIXES = [
  'Main', 'Hot', 'Cold', 'Trading', 'Staking',
  'Vault', 'Reserve', 'Ops', 'Treasury', 'Pool',
  'Yield', 'Farm', 'Stake', 'Swap', 'Bridge',
  'Liquidity', 'Escrow', 'Custody', 'Buffer', 'Exchange',
  'Savings', 'Income', 'Profit', 'Revenue', 'Capital'
];

const WALLET_TYPES = [
  'BTC', 'ETH', 'USDT', 'USDC', 'BASE',
  'SOL', 'DOT', 'ADA', 'AVAX', 'MATIC',
  'BNB', 'XRP', 'LINK', 'UNI', 'AAVE',
  'DAI', 'DOGE', 'SHIB', 'TRX', 'FTM'
];

const WALLET_PURPOSES = [
  'Wallet', 'Account', 'Storage', 'Vault', 'Fund',
  'Pool', 'Reserve', 'Treasury', 'Holdings', 'Assets',
  'Balance', 'Funds', 'Capital', 'Deposit', 'Savings'
];

function WalletModal({ onClose, onSubmit, types, networks }) {
  // Функция генерации случайного имени кошелька
  const generateWalletName = () => {
    const prefix = WALLET_PREFIXES[Math.floor(Math.random() * WALLET_PREFIXES.length)];
    const type = WALLET_TYPES[Math.floor(Math.random() * WALLET_TYPES.length)];
    const purpose = WALLET_PURPOSES[Math.floor(Math.random() * WALLET_PURPOSES.length)];
    return `${prefix} ${type} ${purpose}`;
  };

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    network: '',
    address: '',
    private_key: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      name: formData.name.trim() || generateWalletName()
    };
    onSubmit(submissionData);
  };

  return (
    <div className="enterprise-modal-overlay">
      <div className="enterprise-modal-content">
        <div className="enterprise-modal-header">
          <h3>[+] Connect Existing Wallet</h3>
          <button onClick={onClose} className="enterprise-action-btn enterprise-delete-btn">[×]</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="enterprise-form-group">
            <label>Wallet Name: (optional)</label>
            <div className="input-with-button">
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder="Leave empty for random name"
              />
              <button 
                type="button" 
                className="enterprise-action-btn"
                onClick={() => setFormData({ ...formData, name: generateWalletName() })}
              >
                [↺]
              </button>
            </div>
          </div>
          <div className="enterprise-form-group">
            <label>Wallet Type:</label>
            <select
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              required
            >
              <option value="">Select type</option>
              {types?.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="enterprise-form-group">
            <label>Network:</label>
            <select
              value={formData.network}
              onChange={(e) => setFormData({ ...formData, network: e.target.value })}
              required
            >
              <option value="">Select network</option>
              {networks?.map(network => (
                <option key={network.id} value={network.id}>
                  {network.name}
                </option>
              ))}
            </select>
          </div>
          <div className="enterprise-form-group">
            <label>Wallet Address:</label>
            <input
              type="text"
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              placeholder="e.g. 0x1234..."
              required
            />
          </div>
          <div className="enterprise-form-group">
            <label>Private Key:</label>
            <input
              type="password"
              value={formData.private_key}
              onChange={(e) => setFormData({ ...formData, private_key: e.target.value })}
              placeholder="Enter wallet's private key"
              required
            />
          </div>
          <div className="enterprise-modal-actions">
            <button type="submit" className="enterprise-action-btn">
              [✓] Connect Wallet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WalletModal; 