import React, { useState } from 'react';
import '../styles/SubscriptionStatus.css';
import { Link } from 'react-router-dom';

const HelpPopup = ({ onClose }) => (
  <div className="xnd-help-popup">
    <button className="xnd-help-close" onClick={onClose}>[x]</button>
    <div className="xnd-help-title">[SYSTEM] Что дальше?</div>
    <ul className="xnd-help-list">
      <li className="xnd-help-item">Подключитесь к чату Telegram для общения с сообществом</li>
      <li className="xnd-help-item">
        <Link to="/guides" style={{ color: 'inherit', textDecoration: 'none' }}>
          Изучите гайды в разделе /guides
        </Link>
      </li>
      <li className="xnd-help-item">Задавайте вопросы в чате Telegram если возникнут проблемы</li>
    </ul>
  </div>
);

const SubscriptionStatus = ({ subscription, onRenew }) => {
  const [showHelp, setShowHelp] = useState(false);

  // Рассчитываем количество оставшихся дней
  const calculateDaysRemaining = () => {
    if (!subscription?.expiresAt) return 0;
    const expiryDate = new Date(subscription.expiresAt);
    const today = new Date();
    const diffTime = expiryDate - today;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const daysRemaining = calculateDaysRemaining();
  const showRenewalButton = daysRemaining <= 30 && daysRemaining > 0;

  if (!subscription) {
    return (
      <div className="subscription-status">
        <div className="status-info">
          <span className="status-label">[STATUS]</span>
          <span className="xnd-status-value">LOADING...</span>
        </div>
      </div>
    );
  }

  // Обрабатываем новых пользователей и неактивные подписки
  if (subscription.status === 'new' || subscription.status === 'inactive') {
    return (
      <div className="subscription-status">
        <div className="status-info">
          <span className="status-label">[STATUS]</span>
          <span className="xnd-status-value inactive">INACTIVE</span>
        </div>
        <div className="xnd-welcome-message">
          To access private features and premium content, you need an active subscription.
        </div>
        <div className="xnd-action-buttons">
          <Link to="/private" className="xnd-info-button">
            [?] ABOUT PRIVATE ACCESS
          </Link>
          <button 
            className="xnd-subscribe-button" 
            onClick={onRenew}
          >
            [+] GET ACCESS NOW
          </button>
        </div>
        <div className="xnd-features-list">
          <div className="xnd-feature-item">✓ Access to private guides</div>
          <div className="xnd-feature-item">✓ Priority support</div>
          <div className="xnd-feature-item">✓ API access</div>
          <div className="xnd-feature-item">✓ Telegram notifications</div>
        </div>
      </div>
    );
  }

  // Для активных и истекших подписок
  return (
    <div className="subscription-status">
      <div className="status-info">
        <span className="status-label">[STATUS]</span>
        <span className={`xnd-status-value ${subscription.status}`}>
          {subscription.status.toUpperCase()}
        </span>
        {subscription.status === 'active' && (
          <button 
            className="xnd-help-button"
            onClick={() => setShowHelp(!showHelp)}
          >
            ПОМОЩЬ
          </button>
        )}
        {showHelp && <HelpPopup onClose={() => setShowHelp(false)} />}
      </div>
      
      {subscription.status === 'active' && (
        <>
          <div className="status-info">
            <span className="status-label">[EXPIRES IN]</span>
            <span className={`xnd-status-value ${daysRemaining <= 30 ? 'warning' : ''}`}>
              {daysRemaining} DAYS
            </span>
          </div>
          <div className="xnd-extend-subscription">
            <div className="xnd-extend-message">
              {daysRemaining <= 30 ? (
                <span className="warning">Your subscription will expire soon. Consider extending it now.</span>
              ) : (
                <span>Want more days? You can extend your subscription anytime.</span>
              )}
            </div>
            <button 
              className={`xnd-renewal-button ${daysRemaining <= 30 ? 'warning' : 'active'}`}
              onClick={onRenew}
            >
              {daysRemaining <= 30 ? '[!] RENEW NOW' : '[+] EXTEND SUBSCRIPTION'}
            </button>
          </div>
        </>
      )}
      
      {subscription.status === 'expired' && (
        <div className="xnd-extend-subscription">
          <div className="xnd-extend-message warning">
            Your subscription has expired. Renew now to restore access.
          </div>
          <button 
            className="xnd-renewal-button warning"
            onClick={onRenew}
          >
            [!] RENEW SUBSCRIPTION
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionStatus; 