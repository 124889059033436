import React, { useEffect, useRef } from "react";

const AsciiEarth = ({ dataPoints }) => {
  const canvasRef = useRef(null);
  const isDragging = useRef(false);
  const lastMousePos = useRef({ x: 0, y: 0 });
  const manualRotation = useRef({ x: 0, y: 0 });

  const MAP_WIDTH = 139;
  const MAP_HEIGHT = 34;
  const RADIUS = 350;

  const EARTH_MAP = `............................................................................................................................................
............................................................................................................................................
..................................+++++++..+++++++++++++++++++..............................................................................
......................+.+++++..+.+.+++++........++++++++++++++.............................+..........++++++++++++++..+.....++..............
......++++++++++++++++++++++++++++++++..++++.....++++++++++.................++++++++.....+++++++++++++++++++++++++++++++++++++++++++++++++++
......+++++++++++++++++++++++++++++.....++++......++++...................++++.+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.
........++.......+++++++++++++++++......++++++......................+....++++..++++++++++++++++++++++++++++++++++++++++++++++......++.......
....................++++++++++++++++++.+++++++++....................++..++++++++++++++++++++++++++++++++++++++++++++++++++++.......+........
......................+++++++++++++++++++++++........................+++++++++++++++++++++++++++++++++++++++++++++++++++++++................
......................+++++++++++++++++++++........................++++...+.+++++....++++.+++++++++++++++++++++++++++++++...................
......................+++++++++++++++++++..........................+++........+..++++++++..+++++++++++++++++++++++++...+....+...............
.........................++++++++++++++............................+++++++++..+....++++++++++++++++++++++++++++++++++.......................
..........................++++++......+..........................++++++++++++++++++++++++++++++++++++++++++++++++++++.......................
.............................+++................................++++++++++++++++++++.++++++++....+++++++++++++++++..........................
...............................++.++............................+++++++++++++++++++++.+++++.......++++.....+++++............................
....................................++..........................+++++++++++++++++++++++++..........++.......+.++............................
........................................+++++++..................++++++++++++++++++++++++...................................................
.......................................+++++++++++........................++++++++++++++.....................+...+++........................
.......................................++++++++++++++++...................++++++++++++.......................++..++........++...............
.......................................+++++++++++++++++...................++++++++++.......................................++..............
........................................+++++++++++++++....................+++++++++++..................................+++..+..............
..........................................+++++++++++++....................+++++++++...++............................++++++++++.............
...........................................+++++++++........................++++++++...+..........................+++++++++++++++...........
..........................................+++++++++.........................++++++................................+++++++++++++++...........
..........................................+++++++............................+++...................................+++....+++++++...........
..........................................++++................................................................................+.............
.........................................++++...............................................................................................
.........................................+++................................................................................................
............................................................................................................................................
............................................................................................................................................
............................................................................................................................................
............................................++.....................................+.++++++++++++..+++++++++++++++++++++++++++++++++........
....................+++++++...++++++++++++++++.................+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++......
..........+++++++++++++++++++++++++++++++..........+....+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.......`;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let angle = 0;
    let animationFrameId;
    let lastTime = 0;

    // Преобразуем карту в двумерный массив
    const asciiMap = EARTH_MAP.split("\n").map((line) => line.split(""));
    const points = [];

    // Генерируем точки сферы
    for (let i = 0; i <= MAP_HEIGHT; i++) {
      for (let j = 0; j <= MAP_WIDTH; j++) {
        const char = asciiMap[i] ? asciiMap[i][j] || " " : " ";

        const lat = Math.PI * (i / MAP_HEIGHT);
        const lon = 2 * Math.PI * (j / MAP_WIDTH);

        const x = RADIUS * Math.sin(lat) * Math.cos(lon);
        const y = RADIUS * Math.cos(lat);
        const z = RADIUS * Math.sin(lat) * Math.sin(lon);

        if (char === "+") {
          points.push({
            x,
            y,
            z,
            char: "█",
            brightness: 0.3 + Math.random() * 0.7 // Добавляем случайную яркость
          });
        }
      }
    }

    const rotateY = (point, angleY) => {
      const cosY = Math.cos(angleY + manualRotation.current.y);
      const sinY = Math.sin(angleY + manualRotation.current.y);
      return {
        x: point.x * cosY - point.z * sinY,
        y: point.y,
        z: point.x * sinY + point.z * cosY,
      };
    };

    const handleMouseDown = (e) => {
      isDragging.current = true;
      lastMousePos.current = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging.current) return;

      const deltaX = e.clientX - lastMousePos.current.x;
      const deltaY = e.clientY - lastMousePos.current.y;

      manualRotation.current.y += deltaX * 0.005;
      manualRotation.current.x += deltaY * 0.005;

      lastMousePos.current = { x: e.clientX, y: e.clientY };
    };

    const handleMouseUp = () => {
      isDragging.current = false;
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    const drawGlobe = (timestamp) => {
      const deltaTime = timestamp - lastTime;
      lastTime = timestamp;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Останавливаем автоматическое вращение при перетаскивании
      if (!isDragging.current) {
        const rotationSpeed = 0.03;
        angle += rotationSpeed * (deltaTime / 16.67);
      }

      // Сортируем точки по Z для правильного отображения
      const sortedPoints = points.map(point => {
        const rotated = rotateY(point, angle);
        return { ...point, ...rotated };
      }).sort((a, b) => b.z - a.z);

      sortedPoints.forEach((point) => {
        if (point.z > 0) {
          const screenX = canvas.width / 2 + point.x;
          const screenY = canvas.height / 2 - point.y;

          const brightness = 0.3 + (point.z / RADIUS) * 0.7;
          const alpha = brightness * point.brightness; // Используем сохраненную яркость
          
          ctx.fillStyle = `rgba(51, 255, 51, ${alpha})`;
          ctx.shadowColor = "#33ff33";
          ctx.shadowBlur = 2;
          ctx.font = "14px monospace";
          ctx.fillText(point.char, screenX, screenY);
        }
      });

      // Отрисовка точек датацентров
      if (dataPoints) {
        dataPoints.forEach(point => {
          const lat = (point.y / 50 - 0.5) * Math.PI;
          const lon = (point.x / 50 - 1) * Math.PI;
          
          const x = RADIUS * Math.cos(lat) * Math.sin(lon);
          const y = RADIUS * Math.sin(lat);
          const z = RADIUS * Math.cos(lat) * Math.cos(lon);
          
          const rotated = rotateY({ x, y, z }, angle);
          
          if (rotated.z > 0) {
            const screenX = canvas.width/2 + rotated.x;
            const screenY = canvas.height/2 - rotated.y;
            
            ctx.shadowColor = '#ff3333';
            ctx.shadowBlur = 15;
            
            ctx.fillStyle = '#ff5555';
            ctx.beginPath();
            ctx.arc(screenX, screenY, 4, 0, Math.PI * 2);
            ctx.fill();
            
            if (point.active) {
              ctx.strokeStyle = '#ff3333';
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.arc(screenX, screenY, 8 + Math.sin(Date.now() / 200) * 2, 0, Math.PI * 2);
              ctx.stroke();
            }
          }
        });
      }

      animationFrameId = requestAnimationFrame(drawGlobe);
    };

    animationFrameId = requestAnimationFrame(drawGlobe);

    return () => {
      cancelAnimationFrame(animationFrameId);
      canvas.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dataPoints]);

  return (
    <canvas
      ref={canvasRef}
      width={1920}
      height={1080}
      style={{
        width: "100%",
        height: "auto",
        display: "block",
        cursor: isDragging.current ? "grabbing" : "grab"
      }}
    />
  );
};

export default AsciiEarth;
